import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React, { useState } from 'react';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import Tabs from '../frameWork/Tabs';
import iOrderPageTemplate, {
  OrderPageTemplateCategories,
} from '../../types/orderPageTemplate/iOrderPageTemplate';
import * as _ from 'lodash';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import OrderPageTemplateService from '../../services/orderPageTemplate/OrderPageTemplateService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { Link, useNavigate } from 'react-router-dom';
import { URL_SETTINGS_ORDER_PAGE_TEMPLATE_DETAILS } from '../../helpers/UrlMap';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import MathHelper from '../../helpers/MathHelper';
import Toaster from '../common/Toaster';

export type iOrderPageTemplateList = iComponentWithPageHeader & {
  allowDelete?: boolean;
  allowCreate?: boolean;
};
const OrderPageTemplateList = ({
  headerProps,
  allowCreate,
  allowDelete,
}: iOrderPageTemplateList) => {
  const categories = Object.values(OrderPageTemplateCategories);
  const [selectedCategory, setSelectedCategory] =
    useState<OrderPageTemplateCategories>(categories[0]);
  const navigate = useNavigate();
  const {
    state,
    renderDataTable,
    renderEntityEditPopBtn,
    renderDeleteBtn,
    onSetFilter,
    onSetIsLoading,
    onRefresh,
  } = useListCrudHook<iOrderPageTemplate>({
    sort: `sortOrder:ASC`,
    perPage: 999999,
    filter: { categoryCode: selectedCategory },
    getFn: (params) =>
      OrderPageTemplateService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const convertCategoryCode = (categoryCode: string) => {
    return _.capitalize(categoryCode.replace(/_/g, ' '));
  };

  const getCreateBtn = () => {
    if (allowCreate !== true) {
      return <></>;
    }
    return renderEntityEditPopBtn<iOrderPageTemplate>({
      entityName: 'Order Page Template',
      onSaved: (saved) => {
        navigate(
          URL_SETTINGS_ORDER_PAGE_TEMPLATE_DETAILS.replace(':id', saved.id),
        );
      },
      createFn: async (data) => {
        const lastTemplates = (state.data.data || []).sort(
          (a: iOrderPageTemplate, b: iOrderPageTemplate) =>
            a.sortOrder > b.sortOrder ? -1 : 1,
        );
        const nextSort =
          lastTemplates.length > 0
            ? MathHelper.add(lastTemplates[0].sortOrder, 1)
            : 0;
        return OrderPageTemplateService.create({
          ...data,
          categoryCode: selectedCategory,
          sortOrder: nextSort,
        });
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // it shouldn't even call updateFn.
      updateFn: async () => null,
      getFormFields: ({ entity, isDisabled }) => {
        return [
          {
            fieldName: 'categoryCode',
            label: 'Category',
            isDisabled: true,
            value: convertCategoryCode(selectedCategory),
            testId: 'categoryCode',
          },
          {
            fieldName: 'name',
            label: 'Name',
            isDisabled,
            isRequired: true,
            value: entity?.name || '',
            testId: 'name',
          },
          {
            fieldName: 'description',
            label: 'Description',
            isDisabled,
            value: entity?.description || '',
            testId: 'description',
          },
        ];
      },
    });
  };

  const getColumns = (): iTableColumn<iOrderPageTemplate>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }) => (
        <Link
          to={URL_SETTINGS_ORDER_PAGE_TEMPLATE_DETAILS.replace(':id', data.id)}
        >
          {data.name || ''}
        </Link>
      ),
    },
    {
      key: 'categoryCode',
      header: 'Category',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }) => convertCategoryCode(data.categoryCode || ''),
    },
    {
      key: 'description',
      header: 'Description',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }) => data.description || '',
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iOrderPageTemplate>(
      true,
      true,
    ),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            isSelectable: false,
            cell: ({ data }: iCellParams<iOrderPageTemplate>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    getDisplayName: (data) =>
                      `Order Page Template: ${data.name}`,
                    deleteFnc: () =>
                      OrderPageTemplateService.deactivate(data.id),
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  const submitReorder = (sourceIndex: number, targetIndex: number) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    onSetIsLoading(true);
    const templates = state.data.data || [];
    // Remove the item from the source position
    const [movedItem] = templates.splice(sourceIndex, 1);
    // Insert it at the destination position
    templates.splice(targetIndex, 0, movedItem);
    Promise.all(
      templates.map((template, index) => {
        return OrderPageTemplateService.update(template.id, {
          sortOrder: index,
        });
      }),
    )
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        onRefresh();
      });
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getCreateBtn()}
            title={headerProps?.children}
          />
        ),
      }}
    >
      <Tabs
        activeKey={selectedCategory}
        onSelect={(key) => {
          setSelectedCategory(key as OrderPageTemplateCategories);
          onSetFilter({ categoryCode: key });
        }}
        tabs={Object.values(OrderPageTemplateCategories).map((key) => {
          return {
            eventKey: key,
            title: convertCategoryCode(key),
            tabContent: renderDataTable({
              columns: getColumns(),
              selectiveColumnKey: SelectiveColKeys.ORDER_PAGE_TEMPLATE,
              tblProps: {
                isRankable: true,
                onRankEnd: (params) =>
                  submitReorder(
                    params.sourceIndex,
                    params.destination?.index || 0,
                  ),
                testId: 'order-template-tbl',
              },
            }),
          };
        })}
      />
    </ComponentWithPageHeader>
  );
};

export default OrderPageTemplateList;
