import ServiceHelper from '../helper/ServiceHelper';
import iContact from '../../types/contact/iContact';

const endPoint = '/contact';
const ContactService = {
  getAll: ServiceHelper.getPaginatedAll<iContact>(endPoint),
  get: ServiceHelper.getById<iContact>(endPoint),
  create: ServiceHelper.create<iContact>(endPoint),
  update: ServiceHelper.updateById<iContact>(endPoint),
  deactivate: ServiceHelper.deactivateById<iContact>(endPoint),
};

export default ContactService;
