import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import iUnitOfMeasure from '../../types/unitOfMeasure/iUnitOfMeasure';
import UnitOfMeasureService from '../../services/unitOfMeasure/UnitOfMeasureService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';

type iUnitOfMeasureList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};
const UnitOfMeasureList = ({
  allowDelete,
  headerProps,
}: iUnitOfMeasureList) => {
  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iUnitOfMeasure>({
      sort: `name:ASC`,
      getFn: (params) =>
        UnitOfMeasureService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getEditBtn = (UnitOfMeasure?: iUnitOfMeasure) => {
    return renderEntityEditPopBtn<iUnitOfMeasure>({
      editingEntity: UnitOfMeasure,
      entityName: 'Unit Of Measure',
      createFn: (data) => UnitOfMeasureService.create(data),
      updateFn: (id, data) => UnitOfMeasureService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'UnitOfMeasure-name',
        },
        {
          fieldName: 'shortName',
          label: 'ShortName',
          isDisabled,
          isRequired: true,
          value: entity?.shortName || '',
          testId: 'UnitOfMeasure-shortname',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iUnitOfMeasure>[] => [
    {
      key: 'name',
      header: 'Name',
      cell: ({ data }: iCellParams<iUnitOfMeasure>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'shortname',
      header: 'ShortName',
      cell: ({ data }: iCellParams<iUnitOfMeasure>) =>
        `${data?.shortName || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iUnitOfMeasure>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iUnitOfMeasure>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () =>
                      UnitOfMeasureService.deactivate(data.id),
                    getDisplayName: (UnitOfMeasure) => UnitOfMeasure.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: 'UnitOfMeasure-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default UnitOfMeasureList;
