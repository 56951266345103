import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import AttributeSetService from '../../services/attribute/AttributeSetService';
import iAttributeSet, {
  AttributeSetCodes,
} from '../../types/attribute/iAttributeSet';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import React from 'react';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { Link, useNavigate } from 'react-router-dom';
import { URL_SETTINGS_ATTRIBUTE_SET_DETAILS } from '../../helpers/UrlMap';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import AttributeSetEditPopupBtn from './AttributeSetEditPopupBtn';
import SearchTextField from '../frameWork/SearchTextField';
import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';

export type iAttributeSetList = iComponentWithPageHeader & {
  allowCreate?: boolean;
};
const AttributeSetList = ({
  headerProps,
  allowCreate = true,
  ...props
}: iAttributeSetList) => {
  const testIdStr = `AttributeSet-list`;
  const navigate = useNavigate();

  const { state, renderDataTable, onSetFilter } =
    useListCrudHook<iAttributeSet>({
      sort: `name:ASC`,
      perPage: 99999,
      getFn: (params) =>
        AttributeSetService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getColumns = (): iTableColumn<iAttributeSet>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      isSelectable: false,
      isSortable: true,
      cell: ({ data }: iCellParams<iAttributeSet>) => (
        <>
          <Link to={URL_SETTINGS_ATTRIBUTE_SET_DETAILS.replace(':id', data.id)}>
            {data.name}
          </Link>
          <div>
            <small>
              <i>CODE: {data.code}</i>
            </small>
          </div>
        </>
      ),
    },
    {
      key: 'description',
      header: 'Description',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iAttributeSet>) => data.description || '',
    },
    {
      key: 'isMulti',
      header: 'is Multi?',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iAttributeSet>) =>
        DynamicTableHelper.getCheckedIcon(data.settings?.isMulti === true),
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iAttributeSet>(),
  ];

  const getUsedAttributeSetCodes = () => {
    return (state.data.data || []).map((attributeSet) => attributeSet.code);
  };

  const getUnUsedAttributeSetCodes = () => {
    return Object.values(AttributeSetCodes).filter(
      (attributeCode) => getUsedAttributeSetCodes().indexOf(attributeCode) < 0,
    );
  };
  return (
    <ComponentWithPageHeader
      {...props}
      headerProps={{
        ...headerProps,
        children:
          allowCreate !== true || getUnUsedAttributeSetCodes().length <= 0 ? (
            headerProps?.children
          ) : (
            <PageTitleWithCreateBtn
              title={headerProps?.children}
              createBtn={
                <AttributeSetEditPopupBtn
                  excludingAttributeSetCodes={getUsedAttributeSetCodes()}
                  onSaved={(saved) => {
                    navigate(
                      URL_SETTINGS_ATTRIBUTE_SET_DETAILS.replace(
                        ':id',
                        saved.id,
                      ),
                    );
                  }}
                  renderBtn={(onClick) =>
                    getCreateIconBtn({
                      onClick: onClick,
                    })
                  }
                />
              }
            />
          ),
        actions: (
          <SearchTextField
            testId={`${testIdStr}-search-field`}
            placeholder={'Search name, code ...'}
            onSearch={(searchText) => {
              const searchTxt = `${searchText || ''}`.trim();
              onSetFilter(
                searchTxt === ''
                  ? {}
                  : {
                      [OP_OR]: [
                        { name: { [OP_LIKE]: `%${searchTxt}%` } },
                        { code: { [OP_LIKE]: `%${searchTxt}%` } },
                        { description: { [OP_LIKE]: `%${searchTxt}%` } },
                      ],
                    },
              );
            }}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: testIdStr,
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default AttributeSetList;
