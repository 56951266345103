import {
  default as Origin,
  DropdownItem as OriginItem,
  DropdownItemGroup as OriginGroup,
  DropdownMenuProps,
} from '@atlaskit/dropdown-menu';
import styled from 'styled-components';

export const DropdownItem = OriginItem;
export const DropdownItemGroup = OriginGroup;

export type iDropdownMenuProps = DropdownMenuProps & {
  withWrapper?: boolean;
};
const Wrapper = styled.div`
  position: relative;
  > [data-placement] {
    position: absolute !important;
    &[data-placement^='bottom'] {
      //top: auto !important;
    }
  }
`;
const DropdownMenu = ({ withWrapper = true, ...props }: iDropdownMenuProps) => {
  if (withWrapper !== true) {
    return <Origin {...props} />;
  }
  return (
    <Wrapper>
      <Origin {...props} shouldRenderToParent />
    </Wrapper>
  );
};
export default DropdownMenu;
