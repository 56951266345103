import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import iProductType from '../../types/product/iProductType';
import ProductTypeService from '../../services/product/ProductTypeService';
import { OP_LIKE } from '../../services/ServiceHelper';
import { iOptionWithData } from '../frameWork/Select';

export type iProductTypeSelector = iPreloadAsyncSelectProps & {
  productTypeIds?: string[];
};
const ProductTypeSelector = ({
  productTypeIds = [],
  ...props
}: iProductTypeSelector) => {
  return (
    <PreloadedAsyncSelector<iProductType>
      placeholder={'Select a Product Type ...'}
      {...props}
      optionsUniqBy={(data: iProductType) => data.id}
      formatOptionLabel={(data: iOptionWithData<iProductType>) => (
        <>
          <div>{data.data.name}</div>
          <small>
            <i>{data.data.description || ' '}</i>
          </small>
        </>
      )}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return ProductTypeService.getAll({
          ...params,
          sort: 'name:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(productTypeIds?.length > 0 ? { id: productTypeIds } : {}),
            ...(searchTxtStr === ''
              ? {}
              : { name: { [OP_LIKE]: `%${searchTxtStr}%` } }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        ProductTypeService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default ProductTypeSelector;
