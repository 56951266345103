import React, { useState } from 'react';
import PDFEditorHelper from './PDFEditorHelper';
import PDFEditorEditView from './components/PDFEditorEditView';
import { PDFViewer } from '@react-pdf/renderer';
import styled from 'styled-components';
import iPDFData from './iPDFData';

const Wrapper = styled.div`
  height: 100vh; /* Full height container */
  display: flex;
  .edit-view {
    background-color: #eee;
    overflow: auto;
    flex-shrink: 0;
    transition: width 0.2s ease;
    width: 100%;
  }
  &.with-preview {
    .edit-view {
      width: 55%;
    }
  }

  .preview-wrapper {
    flex: 1;
    overflow: hidden;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
`;
type iPDFEditor = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  testId?: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChanged: (changedData: any) => void;
};

const PDFEditor = ({ testId, data, className }: iPDFEditor) => {
  const componentName = 'pdfEditor';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [showingPreview, setShowingPreview] = useState(false);
  const [content, setContent] = useState('PDF');

  const templateData: iPDFData = {
    pages: [{ content: [{ type: 'TEXT', content: content }] }],
  };

  return (
    <Wrapper
      className={`${componentName} ${className || ''} ${showingPreview ? 'with-preview' : ''}`}
      data-testid={testIdStr}
    >
      <PDFEditorEditView
        className={'edit-view'}
        data={data}
        onChanged={(chg) => setContent(chg)}
        onShowPreview={setShowingPreview}
        isShowingPreview={showingPreview}
      />
      {showingPreview === true && (
        <div className="preview-wrapper">
          <PDFViewer>
            {PDFEditorHelper.convertDataToPDFComponent(templateData)}
          </PDFViewer>
        </div>
      )}
    </Wrapper>
  );
};

export default PDFEditor;
