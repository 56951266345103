import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import iContact from '../../types/contact/iContact';
import ContactService from '../../services/contact/ContactService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import StringHelper from '../../helpers/StringHelper';

export type iContactList = iComponentWithPageHeader & {
  allowDelete?: boolean;
  allowCreate?: boolean;
  contactCompanyId?: string;
};

const ContactList = ({
  allowDelete = true,
  allowCreate = true,
  headerProps,
  contactCompanyId,
}: iContactList) => {
  const contactCompId = `${contactCompanyId || ''}`.trim();
  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iContact>({
      sort: `firstName:ASC`,
      getFn: (params) =>
        ContactService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(contactCompId === ''
              ? {}
              : { contactCompanyId: contactCompId }),
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getEditBtn = (Contact?: iContact) => {
    return renderEntityEditPopBtn<iContact>({
      editingEntity: Contact,
      entityName: 'Contact',
      createFn: (data) => ContactService.create({ ...data, contactCompanyId }),
      updateFn: (id, data) =>
        ContactService.update(id, { ...data, contactCompanyId }),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`contactCompanyId-${entity?.id || ''}`}
        >
          {entity?.firstName || ''}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'firstName',
          label: 'FirstName',
          isDisabled,
          isRequired: true,
          value: entity?.firstName || '',
          testId: 'firstName',
        },
        {
          fieldName: 'lastName',
          label: 'LastName',
          isDisabled,
          value: entity?.lastName || '',
          testId: 'lastName',
        },
        {
          fieldName: 'email',
          label: 'Email',
          isDisabled,
          value: entity?.email || '',
          testId: 'email',
          isValid: (value: string) => {
            const newValue = `${value || ''}`.trim();
            if (newValue !== '' && !StringHelper.validateEmail(newValue)) {
              return {
                isValid: false,
                errMessages: ['Please provide an valid email address'],
              };
            }
            return { isValid: true, errMessages: [] };
          },
        },
        {
          fieldName: 'phone',
          label: 'Phone',
          isDisabled,
          value: entity?.phone || '',
          testId: 'phone',
        },
        {
          fieldName: 'mobile',
          label: 'Mobile',
          isDisabled,
          value: entity?.mobile || '',
          testId: 'mobile',
        },
        {
          fieldName: 'comments',
          label: 'Comments',
          isDisabled,
          value: entity?.comments || '',
          testId: 'comments',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iContact>[] => [
    {
      key: 'firstName',
      header: 'FirstName',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iContact>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'lastName',
      header: 'LastName',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iContact>) => {
        return `${data.lastName || ''}`;
      },
    },
    {
      key: 'email',
      header: 'Email',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iContact>) => `${data.email || ''}`,
    },
    {
      key: 'phone',
      header: 'Phone',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iContact>) => `${data.phone || ''}`,
    },
    {
      key: 'mobile',
      header: 'Mobile',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iContact>) => `${data.mobile || ''}`,
    },
    {
      key: 'comments',
      header: 'Comments',
      isDefault: false,
      isSelectable: true,
      cell: ({ data }: iCellParams<iContact>) => `${data.comments || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iContact>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iContact>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => ContactService.deactivate(data.id),
                    getDisplayName: (contact) => `${contact.firstName || ''}`,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={allowCreate === true ? getEditBtn() : null}
            title={headerProps?.children}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        selectiveColumnKey: SelectiveColKeys.CONTACT_LIST,
        tblProps: {
          testId: 'Contact-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default ContactList;
