import iBaseType from '../iBaseType';
export enum AssetTypes {
  HOUSE_STYLE_PHOTOS = 'HOUSE_STYLE_PHOTOS',
  PRODUCT_PHOTOS = 'PRODUCT_PHOTOS',
  CONTRACT_FILES = 'CONTRACT_FILES',
  ATTRIBUTE_VALUES = 'ATTRIBUTE_VALUES',
}

type iAsset = iBaseType & {
  type: string;
  fileName: string;
  mimeType: string;
  fileSize?: number | null;
  comments?: string | null;
  url?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  externalObj?: any | null;
  externalId?: string | null;
  isPublic: boolean;
};

export default iAsset;
