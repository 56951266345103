import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import iBuild from '../../types/build/iBuild';
import InlineEdit from '../frameWork/InlineEdit';
import StringHelper from '../../helpers/StringHelper';
import BuildService from '../../services/build/BuildService';
import { useState } from 'react';
import Toaster from '../common/Toaster';
import styled from 'styled-components';
import Flex from '../frameWork/Flex';
import Tokens from '../frameWork/Tokens';
import Toggle from '../frameWork/Toggle';
import iBuildStyle, { sizeFields } from '../../types/build/iBuildStyle';
import EntityNames from '../../helpers/EntityNames';
import { iConfigParams } from '../../services/AppService';
import BuildStyleService from '../../services/build/BuildStyleService';
import * as _ from 'lodash';

const Wrapper = styled.div`
  form {
    margin-top: 0px;
  }
  .input-post {
    padding: ${Tokens('space.050', '8px')};
  }
`;

export type iBuildSizeEditPanel = iComponentWithPageHeader & {
  entity: iBuild | iBuildStyle;
  entityName: EntityNames;
  isDisabled?: boolean;
  onSaved?: (saved: iBuild | iBuildStyle) => void;
};

const BuildSizeEditPanel = ({
  entity,
  entityName,
  onSaved,
  isDisabled = false,
  ...props
}: iBuildSizeEditPanel) => {
  const [isSaving, setIsSaving] = useState(false);

  const validateSize = (newValue: string) => {
    const newValueStr = `${newValue || ''}`.trim();
    if (newValueStr !== '' && !StringHelper.isNumeric(newValueStr)) {
      return 'Size needs to be a number';
    }
    return '';
  };

  const handleFieldChanged = (
    fieldName: string,
    newValue: string | boolean,
  ) => {
    const newValueStr = `${newValue || ''}`.trim();
    const newData: iConfigParams = {
      [fieldName]:
        newValue === true
          ? true
          : newValue === false
            ? false
            : newValueStr === ''
              ? null
              : newValueStr,
    };
    const savingFn =
      entityName === EntityNames.Build
        ? BuildService.update
        : entityName === EntityNames.BuildStyle
          ? BuildStyleService.update
          : null;
    if (!savingFn) {
      return;
    }
    setIsSaving(true);
    savingFn(entity.id, newData)
      .then((resp) => {
        setIsSaving(false);
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  const getBuildSizeInputs = () => {
    if (entityName !== EntityNames.Build) {
      return null;
    }
    const editingEntity = entity as iBuild;
    return (
      <>
        <Toggle
          label={'Is Corner Block?'}
          isDisabled={isDisabled}
          separatedLines
          testId={'is-corner'}
          isChecked={editingEntity.isCornerBlock === true}
          onChange={(event) =>
            handleFieldChanged('isCornerBlock', event.target.checked)
          }
        />
        <InlineEdit
          readViewFitContainerWidth
          placeHolder={'input the size in m2'}
          testId={'blockSize'}
          onConfirm={(newValue) => handleFieldChanged('blockSize', newValue)}
          isDisabled={isSaving || isDisabled}
          validate={validateSize}
          label={
            <>
              Block Size (m<sup>2</sup>)
            </>
          }
          value={`${editingEntity.blockSize || ''}`.trim()}
          editViewProps={{
            elemAfterInput: (
              <div className={'input-post'}>
                m<sup>2</sup>
              </div>
            ),
          }}
        />
      </>
    );
  };

  return (
    <Wrapper>
      <ComponentWithPageHeader {...props}>
        <Flex className={'gap-05 align-items-start flex-wrap'}>
          {getBuildSizeInputs()}
          <InlineEdit
            readViewFitContainerWidth
            testId={'houseSizeInSquare'}
            placeHolder={'input the size in square'}
            value={`${entity.houseSizeInSquare || ''}`.trim()}
            onConfirm={(newValue) =>
              handleFieldChanged('houseSizeInSquare', newValue)
            }
            isDisabled={isSaving || isDisabled}
            label={<>House Size in Square</>}
            validate={validateSize}
          />
          {sizeFields.map((fieldName) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const value = fieldName in entity ? entity[fieldName] : '';
            const valStr = `${value || ''}`.trim();
            return (
              <InlineEdit
                key={fieldName}
                testId={fieldName}
                readViewFitContainerWidth
                placeHolder={'input the size in m2'}
                onConfirm={(newValue) =>
                  handleFieldChanged(fieldName, newValue)
                }
                isDisabled={isSaving || isDisabled}
                label={
                  <>
                    {_.capitalize(fieldName.toLowerCase().replace('size', ''))}{' '}
                    Size (m
                    <sup>2</sup>)
                  </>
                }
                value={valStr}
                editViewProps={{
                  elemAfterInput: (
                    <div className={'input-post'}>
                      m<sup>2</sup>
                    </div>
                  ),
                }}
                validate={validateSize}
              />
            );
          })}
        </Flex>
      </ComponentWithPageHeader>
    </Wrapper>
  );
};

export default BuildSizeEditPanel;
