type iGetWrapperProps = {
  componentName: string;
  className?: string;
  testId?: string;
};
const getWrapperProps = ({
  componentName,
  className,
  testId,
}: iGetWrapperProps) => {
  return {
    className: `${componentName} ${className || ''}`.trim(),
    testId: `${componentName}-${testId || ''}`.trim(),
  };
};

const ComponentPropsHelper = {
  getWrapperProps,
};

export default ComponentPropsHelper;
