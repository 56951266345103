import styled from 'styled-components';
import Select, { iOption } from '../../frameWork/Select';
import Flex from '../../frameWork/Flex';
import { getFooterWithBtns } from '../../common/PopupModal';
import Icons from '../../frameWork/Icons';
import AttributeSelector from '../AttributeSelector';
import { useState } from 'react';
import AttributeValueInput from './AttributeValueInput';
import iAttribute from '../../../types/attribute/iAttribute';
import md5 from 'md5';

import AttributeSettingsHelper, {
  iEditLogic,
  iEditLogicThen,
  iEditLogicWhen,
  ThenActions,
  WhenActions,
} from './AttributeSettingsHelper';
import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../../common/PopupBtn';
import FormError, { getErrorProps, iErrorMap } from '../../form/FormError';
import Toaster, { TOAST_TYPE_ERROR } from '../../common/Toaster';

const Wrapper = styled.div`
  .when-div,
  .then-div {
    > * {
      width: 32%;
    }
  }
`;
type iAttributeDisplayLogicEditPanel = Omit<iPopupBtn, 'titleId'> & {
  attribute?: iAttribute;
  className?: string;
  testId?: string;
  editLogic?: iEditLogic;
  onSave: (saved: iEditLogic, isCreated: boolean) => void;
};
const AttributeDisplayLogicEditPanel = ({
  testId,
  className,
  attribute,
  editLogic,
  onSave,
  ...props
}: iAttributeDisplayLogicEditPanel) => {
  const componentName = 'attribute-edit-logic';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [editingData, setEditingData] = useState<iEditLogic | null>(null);
  const [errorMap, setErrorMap] = useState<iErrorMap>({});

  const getWhenAttributeValueInput = (whenIndex = 0) => {
    const whereArr = editingData?.when || editLogic?.when || [];
    const currentWhereObj: iEditLogicWhen =
      whereArr.length > whenIndex ? whereArr[whenIndex] : { type: null };

    if (
      currentWhereObj.type !== WhenActions.AttrValueIs ||
      !currentWhereObj.attribute
    ) {
      return null;
    }
    const vals = currentWhereObj.attrValues || [];
    const whenAttrValueErrors =
      'whenAttrValueErrors' in errorMap
        ? errorMap.whenAttrValueErrors || []
        : [];
    const whenAttrValueError = `${whenAttrValueErrors[whenIndex] || ''}`.trim();
    const error =
      whenAttrValueError === '' || !currentWhereObj.attribute?.id
        ? {}
        : { [currentWhereObj.attribute.id]: [whenAttrValueError] };
    return (
      <AttributeValueInput
        appearance={'default'}
        attribute={currentWhereObj.attribute}
        attributeSection={null}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        attributeValue={{
          value: vals.length > 0 ? `${vals[0] || ''}` : '',
        }}
        errorMap={error}
        onChange={(newValue) => {
          whereArr[whenIndex] = {
            ...currentWhereObj,
            attrValues: [`${newValue || ''}`],
          };
          setEditingData({
            ...(editingData || {}),
            when: whereArr,
          });
        }}
      />
    );
  };

  const getWhereConditionsPanel = (whenIndex = 0) => {
    const whereArr = editingData?.when || editLogic?.when || [];
    const currentWhereObj: iEditLogicWhen =
      whereArr.length > whenIndex ? whereArr[whenIndex] : { type: null };
    const selectedType = `${currentWhereObj.type || ''}`.trim();
    const whenActionOptions = Object.values(
      AttributeSettingsHelper.whenActionOptions,
    );
    const whenAttributesErrors =
      'whenAttributes' in errorMap ? errorMap.whenAttributes || [] : [];
    const whenAttributeError =
      `${whenAttributesErrors[whenIndex] || ''}`.trim();
    return (
      <>
        <Flex
          className={'gap-1 align-items-start justify-content-start when-div'}
        >
          <Select
            label={'When'}
            testId={'when-type-selector'}
            options={whenActionOptions}
            formatOptionLabel={(data) => data.display}
            value={
              selectedType === ''
                ? undefined
                : whenActionOptions.filter(
                    (option) => option.value === selectedType,
                  )
            }
            onChange={(option) => {
              whereArr[whenIndex] = {
                ...currentWhereObj,
                type: option ? (`${option?.value}` as WhenActions) : null,
                attribute: undefined,
                attrValues: undefined,
              };
              setEditingData({
                ...editingData,
                when: whereArr,
              });
            }}
          />
          <AttributeSelector
            value={currentWhereObj.attribute?.id || ''}
            label={'Target attribute'}
            testId={'when-attribute-selector'}
            excludeIds={
              `${attribute?.id || ''}`.trim() !== ''
                ? [`${attribute?.id || ''}`.trim()]
                : undefined
            }
            {...getErrorProps({
              fieldName: 'whenAttributeError',
              error: whenAttributeError === '' ? {} : { whenAttributeError },
            })}
            onChange={(option: iOption) => {
              whereArr[whenIndex] = {
                ...currentWhereObj,
                attribute: option.data,
                attrValues: undefined,
              };
              setEditingData({
                ...editingData,
                when: whereArr,
              });
            }}
          />
          {getWhenAttributeValueInput(whenIndex)}
        </Flex>
      </>
    );
  };

  const getThenAttributeValueInput = () => {
    const thenObj: iEditLogicThen = editingData?.then ||
      editLogic?.then || { type: null };
    if (
      [ThenActions.AttrLimitValues, ThenActions.AttrValueWillBe].indexOf(
        thenObj.type as ThenActions,
      ) < 0
    ) {
      return null;
    }

    const vals = thenObj.attrValues || [];
    const needsToBeMulti = thenObj.type === ThenActions.AttrLimitValues;
    return (
      <AttributeValueInput
        isMulti={needsToBeMulti}
        appearance={'default'}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        attribute={attribute}
        attributeSection={null}
        attributeValue={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          value: needsToBeMulti === true ? vals : `${vals[0] || ''}`,
        }}
        errorMap={
          'thenAttributes' in errorMap && attribute?.id
            ? { [attribute.id]: errorMap.thenAttributes }
            : {}
        }
        onChange={(newValues) => {
          setEditingData({
            ...(editingData || {}),
            then: {
              ...thenObj,
              attrValues: newValues
                ? Array.isArray(newValues)
                  ? newValues
                  : [newValues]
                : undefined,
            },
          });
        }}
      />
    );
  };

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    setEditingData(null);
    setErrorMap({});
    setModelShowing(false);
  };

  const preSave = (): boolean => {
    const errors: iErrorMap = {};
    const checkingData = {
      ...(editLogic || {}),
      ...(editingData || {}),
    };
    const whens = checkingData.when || [];
    if (whens.length <= 0) {
      const msg = 'At least one when condition is required.';
      errors.whens = [msg];
      Toaster.showToast(msg, TOAST_TYPE_ERROR);
    }
    const whenAttrErrors: string[] = [];
    const whenAttrValueErrors: string[] = [];
    whens.forEach((when, i) => {
      const attribute = when.attribute || null;
      if (!attribute) {
        whenAttrErrors[i] = 'Attribute is required';
      }
      const attributeValues = when.attrValues || [];
      if (
        when.type === WhenActions.AttrValueIs &&
        attributeValues.length <= 0
      ) {
        whenAttrValueErrors[i] = 'Value is required';
      }
    });
    if (whenAttrErrors.length > 0) {
      errors.whenAttributes = whenAttrErrors;
    }
    if (whenAttrValueErrors.length > 0) {
      errors.whenAttrValueErrors = whenAttrValueErrors;
    }

    const then: iEditLogicThen | null = checkingData.then || null;
    const thenType = `${then?.type || ''}`.trim();
    const thenValues = then?.attrValues || [];
    if (thenType === '') {
      errors.thenType = ['Type is required'];
    }
    if (
      [ThenActions.AttrValueWillBe, ThenActions.AttrLimitValues].indexOf(
        thenType as ThenActions,
      ) >= 0 &&
      thenValues.length <= 0
    ) {
      errors.thenAttributes = ['Value is required'];
    }
    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const handleSave = (setModelShowing: iSetShowingModalFn) => {
    if (!preSave()) {
      return;
    }
    const data = {
      ...(editLogic || {}),
      ...(editingData || {}),
      when: (editingData?.when || editLogic?.when || []).map((whereObj) => {
        return whereObj;
      }),
    };
    const editLogicId = `${editLogic?.id || ''}`.trim();
    onSave(
      {
        ...data,
        ...(editLogicId === '' ? { id: md5(JSON.stringify(data)) } : {}),
      },
      editLogicId === '',
    );
    handleClose(setModelShowing);
  };

  const getBody = () => {
    const thenActionOptions = Object.values(
      AttributeSettingsHelper.thenActionOptions,
    );
    const thenObj: iEditLogicThen = editingData?.then ||
      editLogic?.then || { type: null };
    const selectedType = `${thenObj.type || ''}`.trim();
    return (
      <Wrapper
        data-testid={testIdStr}
        className={`${componentName} ${className || ''}`}
      >
        {getWhereConditionsPanel()}
        <Flex
          className={'gap-1 align-items-start justify-content-start then-div'}
        >
          <Select
            label={'Then'}
            testId={'then-type-selector'}
            options={thenActionOptions}
            formatOptionLabel={(data) => data.display}
            value={thenActionOptions.filter(
              (option) => option.value === selectedType,
            )}
            {...getErrorProps({
              fieldName: 'thenType',
              error: errorMap,
            })}
            onChange={(selected) => {
              setEditingData({
                ...(editingData || {}),
                then: {
                  type: selected ? (`${selected?.value}` as ThenActions) : null,
                },
              });
            }}
          />
          {getThenAttributeValueInput()}
        </Flex>
      </Wrapper>
    );
  };

  return (
    <PopupBtn
      {...props}
      titleId={'AttributeEditPopupBtn'}
      modalProps={(setModelShowing) => ({
        onClose: () => handleClose(setModelShowing),
        shouldScrollInViewport: true,
        width: '70rem',
        footer: getFooterWithBtns({
          cancelBtnProps: {
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            onClick: () => handleSave(setModelShowing),
            btnText: 'Save',
            isDisabled: Object.keys(editingData || {}).length <= 0,
            iconBefore: Icons.SendIcon,
            testId: 'save-btn',
          },
          preElement: <FormError fieldName={'whens'} error={errorMap} />,
        }),
        title: (
          <>
            {`${editLogic?.id || ''}`.trim() === '' ? 'Creating ' : 'Updating '}
            an editing logic for{' '}
            <small>
              {`${attribute?.attributeSetCode || ''}`
                .replace('_', ' ')
                .toUpperCase()}{' '}
              / {attribute?.name || ''}
            </small>
          </>
        ),
        body: getBody(),
      })}
    />
  );
};

export default AttributeDisplayLogicEditPanel;
