import ServiceHelper from '../helper/ServiceHelper';
import iBuildStoreyHeight from '../../types/build/iBuildStoreyHeight';

const endPoint = '/buildStoreyHeight';
const BuildStoreyHeightService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildStoreyHeight>(endPoint),
  get: ServiceHelper.getById<iBuildStoreyHeight>(endPoint),
  create: ServiceHelper.create<iBuildStoreyHeight>(endPoint),
  update: ServiceHelper.updateById<iBuildStoreyHeight>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildStoreyHeight>(endPoint),
};

export default BuildStoreyHeightService;
