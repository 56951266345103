import { AttributeSetCodes } from '../../types/attribute/iAttributeSet';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import styled from 'styled-components';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import iAttribute, {
  AttributeForSections,
  AttributeTypes,
} from '../../types/attribute/iAttribute';
import Flex from '../frameWork/Flex';
import { SimpleTag } from '../frameWork/Tag';
import AttributeSettingsHelper from './components/AttributeSettingsHelper';
import Icons from '../frameWork/Icons';
import AttributeService from '../../services/attribute/AttributeService';
import React, { useState } from 'react';
import Toaster from '../common/Toaster';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import AttributeEditPopupBtn from './AttributeEditPopupBtn';
import { getCreateIconBtn } from '../common/PageTitleWithCreateBtn';
import { Link } from 'react-router-dom';
import { URL_SETTINGS_ATTRIBUTE_SET_DETAILS } from '../../helpers/UrlMap';
import { OP_LIKE } from '../../services/ServiceHelper';
import SearchTextField from '../frameWork/SearchTextField';
import AdvanceSearchPanel, {
  getDefaultAdvBtn,
} from '../frameWork/AdvanceSearchPanel';
import { iConfigParams } from '../../services/AppService';
import { iOption } from '../frameWork/Select';
import AttributeInputTypeSelector from './AttributeInputTypeSelector';
import Button from '../frameWork/Button';
import AttributeSetSelector from './AttributeSetSelector';

const Wrapper = styled.div`
  .dynamic-tbl-cell-name {
    width: 8rem;
  }
  .dynamic-tbl-cell-type {
    width: 6rem;
  }
  .dynamic-tbl-cell-hasEditLogic {
    width: 4rem;
  }
  .dynamic-tbl-cell-btns {
    width: 40px;
  }
  .dynamic-tbl-cell-AttributeSet {
    width: 8rem;
  }
  [class^='dynamic-tbl-cell-for-section-'] {
    width: 5rem;
  }
`;
type iAttributeList = iComponentWithPageHeader & {
  attributeSetCode?: AttributeSetCodes;
  testId?: string;
  selectiveColumnKey?: string;
  className?: string;
  allowEdit?: boolean;
  allowDelete?: boolean;
  forceReload?: number;
  pageSize?: number;
  isRankable?: boolean;
};
const AttributeList = ({
  attributeSetCode,
  testId,
  className,
  allowEdit = true,
  allowDelete = true,
  forceReload = 0,
  headerProps,
  selectiveColumnKey,
  pageSize,
  isRankable,
  ...props
}: iAttributeList) => {
  const componentName = 'AttributeList';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [showingAdvSearch, setShowingAdvSearch] = useState(false);
  const [advSearchCriteria, setAdvSearchCriteria] = useState<iConfigParams>({});
  const {
    state,
    renderDataTable,
    renderDeleteBtn,
    onRefresh,
    onSetIsLoading,
    onSetFilter,
    viewingState,
  } = useListCrudHook<iAttribute>({
    sort: `sort:ASC`,
    forceReload,
    perPage: pageSize,
    getFn: (params) =>
      AttributeService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(`${attributeSetCode || ''}`.trim() !== ''
            ? { attributeSetCode: attributeSetCode }
            : {}),
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy,AttributeSet',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getEditBtn = (attribute?: iAttribute) => {
    if (!allowEdit || !attribute?.AttributeSet) {
      return null;
    }
    return (
      <AttributeEditPopupBtn
        attributeSet={attribute.AttributeSet}
        attribute={attribute}
        onSaved={() => onRefresh()}
        renderBtn={(onClick) => {
          if (`${attribute?.id || ''}`.trim() === '') {
            return getCreateIconBtn({ onClick });
          }
          return (
            <a onClick={onClick} className={'cursor-pointer'}>
              {attribute?.name}
            </a>
          );
        }}
      />
    );
  };

  const submitReorder = (sourceIndex: number, targetIndex: number) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    onSetIsLoading(true);
    const attributes = state.data.data || [];
    // Remove the item from the source position
    const [movedItem] = attributes.splice(sourceIndex, 1);
    // Insert it at the destination position
    attributes.splice(targetIndex, 0, movedItem);
    Promise.all(
      attributes.map((attribute, index) => {
        return AttributeService.update(attribute.id, { sort: index });
      }),
    )
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        onRefresh();
      });
  };

  const getColumns = (): iTableColumn<iAttribute>[] => [
    {
      key: 'AttributeSet',
      header: 'Attribute Set',
      isDefault: attributeSetCode === undefined,
      isSelectable: true,
      cell: ({ data }: iCellParams<iAttribute>) => {
        const attributeSetId = `${data.AttributeSet?.id || ''}`.trim();
        return (
          <>
            {attributeSetId === '' ? null : (
              <Link
                to={URL_SETTINGS_ATTRIBUTE_SET_DETAILS.replace(
                  ':id',
                  attributeSetId,
                )}
              >
                {data.AttributeSet?.name || ''}
              </Link>
            )}
            <div>
              <small>
                <i>Code: {data.attributeSetCode || ''}</i>
              </small>
            </div>
          </>
        );
        return allowEdit !== true ? data.name : getEditBtn(data);
      },
    },
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      cell: ({ data }: iCellParams<iAttribute>) => {
        return allowEdit !== true ? data.name : getEditBtn(data);
      },
    },
    {
      key: 'type',
      header: 'Type',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iAttribute>) => {
        return data.type;
      },
    },
    {
      key: 'requiredWhenOrderGen',
      header: 'Req. When Order Gen.',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iAttribute>) => {
        return DynamicTableHelper.getCheckedIcon(data.isRequired);
      },
    },
    {
      key: 'options',
      header: 'Options',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iAttribute>) => {
        if (data.type !== AttributeTypes.DROPDOWN) {
          return null;
        }
        const options = data.settings?.options || [];
        return (
          <Flex className={'gap-050 align-items-start flex-wrap'}>
            {options.map((option: string) => (
              <SimpleTag text={option} key={option} />
            ))}
          </Flex>
        );
      },
    },
    ...Object.values(AttributeForSections).map((forSection) => ({
      key: `for-section-${forSection}`,
      header: `For ${forSection}`,
      isDefault: false,
      isSelectable: true,
      group: 'For Section',
      cell: ({ data }: iCellParams<iAttribute>) => {
        const isEditable =
          AttributeSettingsHelper.isAttributeEditableForSection(
            data,
            forSection,
          );
        const isRequired =
          AttributeSettingsHelper.isAttributeRequiredForSection(
            data,
            forSection,
          );
        const isReadable =
          AttributeSettingsHelper.isAttributeReadableForSection(
            data,
            forSection,
          );
        return (
          <Flex className={'gap-050 align-items-center flex-wrap'}>
            {isEditable ? (
              <Icons.MediaServicesAnnotateIcon label={'Editable'} />
            ) : isReadable ? (
              <Icons.WatchIcon label={'Readable'} />
            ) : null}
            {isRequired && <div style={{ fontSize: '36px' }}>*</div>}
          </Flex>
        );
      },
    })),
    {
      key: 'hasEditLogic',
      header: 'Has Edit Logic',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iAttribute>) => {
        const logics = AttributeSettingsHelper.getEditLogic(data);
        return DynamicTableHelper.getCheckedIcon(logics.length > 0);
      },
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iAttribute>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iAttribute>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => AttributeService.deactivate(data.id),
                    getDisplayName: (attribute) => attribute.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  const getAdvSearchPanel = () => {
    if (!showingAdvSearch) {
      return undefined;
    }
    return (
      <AdvanceSearchPanel>
        <Flex className={'flex-wrap gap-05 align-items-end'}>
          <AttributeSetSelector
            label={'Attribute Set'}
            onChange={(selected: iOption) => {
              setAdvSearchCriteria({
                ...advSearchCriteria,
                attributeSetCode: selected && selected.value,
              });
            }}
            value={advSearchCriteria.attributeSetCode}
          />
          <AttributeInputTypeSelector
            isMulti
            label={'Type'}
            testId={'type'}
            className={'type-selector'}
            values={advSearchCriteria.attributeTypes || []}
            value={undefined}
            onChange={(options: iOption[] | null) => {
              setAdvSearchCriteria({
                ...advSearchCriteria,
                attributeTypes:
                  options === null ? null : options.map((opt) => opt.value),
              });
            }}
          />
          <Button
            isLoading={state.isLoading}
            iconBefore={Icons.SearchIcon}
            appearance={'primary'}
            onClick={() => {
              const searchingTypes = advSearchCriteria.attributeTypes || [];
              const searchingSetCode =
                `${advSearchCriteria.attributeSetCode || ''}`.trim();
              onSetFilter({
                ...viewingState.filter,
                type: searchingTypes.length > 0 ? searchingTypes : undefined,
                attributeSetCode:
                  searchingSetCode > '' ? searchingSetCode : undefined,
              });
            }}
          >
            Search
          </Button>
        </Flex>
      </AdvanceSearchPanel>
    );
  };

  const getActions = () => {
    if (headerProps?.actions) {
      return headerProps?.actions;
    }
    return (
      <Flex className={'gap-1 align-items-center'}>
        {getDefaultAdvBtn({
          onClick: () => setShowingAdvSearch(!showingAdvSearch),
          isShowingAdv: showingAdvSearch,
        })}
        <SearchTextField
          testId={`${testIdStr}-search-field`}
          placeholder={'Search name, code ...'}
          onSearch={(searchText) => {
            const searchTxt = `${searchText || ''}`.trim();
            onSetFilter({
              ...viewingState.filter,
              ...(searchTxt === ''
                ? {}
                : {
                    name: { [OP_LIKE]: `%${searchTxt}%` },
                  }),
            });
          }}
        />
      </Flex>
    );
  };

  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${componentName} ${className || ''}`}
    >
      <ComponentWithPageHeader
        {...props}
        headerProps={{
          ...headerProps,
          children: headerProps?.children,
          bottomBar: getAdvSearchPanel(),
          actions: getActions(),
        }}
      >
        {renderDataTable({
          columns: getColumns(),
          selectiveColumnKey:
            selectiveColumnKey || SelectiveColKeys.ATTRIBUTE_LIST,
          tblProps: {
            testId: testIdStr,
            isRankable: allowEdit === true && isRankable === true,
            onRankEnd: (params) =>
              submitReorder(params.sourceIndex, params.destination?.index || 0),
          },
        })}
      </ComponentWithPageHeader>
    </Wrapper>
  );
};

export default AttributeList;
