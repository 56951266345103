import SettingsPage from '../../layouts/SettingsPage';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import MathHelper from '../../helpers/MathHelper';
import { URL_SETTINGS_ORDER_PAGE_TEMPLATE } from '../../helpers/UrlMap';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import iOrderPageTemplate from '../../types/orderPageTemplate/iOrderPageTemplate';
import OrderPageTemplateService from '../../services/orderPageTemplate/OrderPageTemplateService';
import OrderPageTemplateDetailsPanel from '../../components/orderPageTemplate/OrderPageTemplateDetailsPanel';
import Flex from '../../components/frameWork/Flex';
import Badge from '../../components/frameWork/Badge';

const OrderPageTemplateDetailsPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();
  const [forceReload, setForceReload] = useState(0);

  return (
    <ComponentWithPreloadData<iOrderPageTemplate>
      forceReload={forceReload}
      emptyStateProps={{
        description: `Couldn't find requested Template with id: ${idStr}.`,
      }}
      getDataFn={() =>
        OrderPageTemplateService.get(idStr, {
          include: 'CreatedBy,UpdatedBy',
        })
      }
    >
      {({ data }) => (
        <SettingsPage
          title={data.name || ''}
          headerProps={{
            className: 'no-bottom-margin',
          }}
          showTitle={false}
          extraBreadcrumbs={[
            {
              text: 'Order Page Templates',
              href: URL_SETTINGS_ORDER_PAGE_TEMPLATE,
            },
          ]}
        >
          <OrderPageTemplateDetailsPanel
            allowDelete
            allowEdit
            onSaved={() => setForceReload(MathHelper.add(forceReload, 1))}
            orderPageTemplate={data}
            headerProps={{
              children: (
                <Flex className={'align-items-end gap-1'}>
                  <div>{data.name || ''}</div>
                  <Badge>{data.categoryCode}</Badge>
                </Flex>
              ),
              className: 'no-top-margin',
            }}
          />
        </SettingsPage>
      )}
    </ComponentWithPreloadData>
  );
};

export default OrderPageTemplateDetailsPage;
