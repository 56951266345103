import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppProvider from '@atlaskit/app-provider';
import {
  URL_BACKEND,
  URL_CONTACT_COMPANY_LIST,
  URL_CONTACT_COMPANY_DETAILS,
  URL_LOGIN,
  URL_MY_PROFILE,
  URL_SETTINGS,
  URL_SETTINGS_BANKS,
  URL_SETTINGS_COMPANY_PROFILE,
  URL_GAS_TYPES,
  URL_SETTINGS_USERS,
  URL_SUBURBS,
  URL_CITY_COUNCILS,
  URL_BUILD_STYLES,
  URL_BUILD_STYLE_DETAILS,
  URL_HOUSE_AREAS,
  URL_SETTINGS_TAX_RATES,
  URL_BUILDS,
  URL_ESTATE,
  URL_SETTINGS_FINANCE_TYPE,
  URL_HOUSE_STOREY,
  URL_BUILD_DETAILS,
  URL_STATE,
  URL_SETTINGS_BUILD_NOTE_CATEGORY,
  URL_SETTINGS_BUILD_SUSPENSION_TYPE,
  URL_SETTINGS_BUILD_SUSPENSION_REASON,
  URL_SETTINGS_PLAN_REVISION,
  URL_SETTINGS_ATTRIBUTE_SET,
  URL_SETTINGS_ATTRIBUTE_SET_DETAILS,
  URL_PRODUCTS,
  URL_PRODUCT_DETAILS,
  URL_SETTINGS_ATTRIBUTES,
  URL_PRODUCTTYPE,
  URL_SETTINGS_BALRATE,
  URL_SETTINGS_ORDER_PAGE_TEMPLATE,
  URL_SETTINGS_UNITOFMEASURE,
  URL_SETTINGS_ORDER_PAGE_TEMPLATE_DETAILS,
} from './helpers/UrlMap';
import store from './redux/makeReduxStore';
import LoginPage from './pages/auth/LoginPage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/auth/ProfilePage';
import CompanyProfilePage from './pages/settings/CompanyProfilePage';
import UsersPage from './pages/settings/UsersPage';
import 'react-toastify/dist/ReactToastify.css';
import Toaster from './components/common/Toaster';
import BanksPage from './pages/settings/BanksPage';
import GasTypesPage from './pages/misc/GasTypesPage';
import ContactCompaniesPage from './pages/contact/ContactCompaniesPage';
import ContactCompanyDetailsPage from './pages/contact/ContactCompanyDetailsPage';
import PageNotFound from './pages/PageNotFound';
import CityCouncilsPage from './pages/misc/CityCouncilsPage';
import SuburbsPage from './pages/misc/SuburbsPage';
import BuildStylesPage from './pages/buildStyle/BuildStylesPage';
import BuildStyleDetailsPage from './pages/buildStyle/BuildStyleDetailsPage';
import HouseAreasPage from './pages/build/HouseAreaPage';
import TaxRatesPage from './pages/settings/TaxRatesPage';
import BuildsPage from './pages/build/BuildsPage';
import EstatePage from './pages/misc/EstatePage';
import FinanceTypePage from './pages/settings/FinanceTypePage';
import HouseStoreyPage from './pages/build/HouseStoreyPage';
import BuildDetailsPage from './pages/build/BuildDetailsPage';
import StatePage from './pages/misc/StatePage';
import moment from 'moment';
import 'moment/locale/en-au';
import AppHelper from './helpers/AppHelper';
import BuildNoteCategoriesPage from './pages/build/BuildNoteCategoriesPage';
import BuildSuspensionTypesPage from './pages/buildSuspension/BuildSuspensionTypesPage';
import BuildSuspensionReasonsPage from './pages/buildSuspension/BuildSuspensionReasonsPage';
import PlanRevisionsPage from './pages/plan/PlanRevisionsPage';
import AttributeSetsPage from './pages/attribute/AttributeSetsPage';
import AttributeSetDetailsPage from './pages/attribute/AttributeSetDetailsPage';
import ProductsPage from './pages/product/ProductsPage';
import ProductDetailsPage from './pages/product/ProductDetailsPage';
import AttributesPage from './pages/attribute/AttributesPage';
import ProductTypePage from './pages/product/ProductTypePage';
import BalRatePage from './pages/settings/BalRatePage';
import OrderPageTemplatesPage from './pages/settings/OrderPageTemplatesPage';
import UnitOfMeasurePage from './pages/unitOfMeasure/UnitOfMeasurePage';
import OrderPageTemplateDetailsPage from './pages/settings/OrderPageTemplateDetailsPage';

const App = () => {
  moment.locale(AppHelper.getDefaultLocale());
  return (
    <AppProvider>
      <Provider store={store}>
        <Router>
          {/*<Navbar />*/}
          <Routes>
            <Route path={URL_LOGIN} element={<LoginPage />} />

            <Route path={URL_BACKEND} element={<HomePage />} />
            <Route path={URL_MY_PROFILE} element={<ProfilePage />} />

            <Route
              path={URL_CONTACT_COMPANY_LIST}
              element={<ContactCompaniesPage />}
            />
            <Route
              path={URL_CONTACT_COMPANY_DETAILS}
              element={<ContactCompanyDetailsPage />}
            />
            <Route path={URL_CITY_COUNCILS} element={<CityCouncilsPage />} />
            <Route path={URL_SUBURBS} element={<SuburbsPage />} />
            <Route path={URL_STATE} element={<StatePage />} />
            <Route path={URL_HOUSE_AREAS} element={<HouseAreasPage />} />

            <Route path={URL_BUILD_STYLES} element={<BuildStylesPage />} />
            <Route
              path={URL_BUILD_STYLE_DETAILS}
              element={<BuildStyleDetailsPage />}
            />
            <Route path={URL_BUILDS} element={<BuildsPage />} />
            <Route path={URL_HOUSE_STOREY} element={<HouseStoreyPage />} />
            <Route path={URL_BUILD_DETAILS} element={<BuildDetailsPage />} />

            <Route path={URL_SETTINGS} element={<CompanyProfilePage />} />
            <Route
              path={URL_SETTINGS_COMPANY_PROFILE}
              element={<CompanyProfilePage />}
            />
            <Route path={URL_SETTINGS_USERS} element={<UsersPage />} />
            <Route path={URL_SETTINGS_BANKS} element={<BanksPage />} />
            <Route path={URL_GAS_TYPES} element={<GasTypesPage />} />
            <Route path={URL_SETTINGS_TAX_RATES} element={<TaxRatesPage />} />
            <Route path={URL_ESTATE} element={<EstatePage />} />

            <Route path={URL_PRODUCTS} element={<ProductsPage />} />
            <Route
              path={URL_PRODUCT_DETAILS}
              element={<ProductDetailsPage />}
            />
            <Route path={URL_PRODUCTTYPE} element={<ProductTypePage />} />

            <Route
              path={URL_SETTINGS_BUILD_NOTE_CATEGORY}
              element={<BuildNoteCategoriesPage />}
            />
            <Route
              path={URL_SETTINGS_BUILD_SUSPENSION_TYPE}
              element={<BuildSuspensionTypesPage />}
            />
            <Route
              path={URL_SETTINGS_BUILD_SUSPENSION_REASON}
              element={<BuildSuspensionReasonsPage />}
            />
            <Route
              path={URL_SETTINGS_FINANCE_TYPE}
              element={<FinanceTypePage />}
            />
            <Route
              path={URL_SETTINGS_PLAN_REVISION}
              element={<PlanRevisionsPage />}
            />
            <Route
              path={URL_SETTINGS_ATTRIBUTE_SET}
              element={<AttributeSetsPage />}
            />
            <Route
              path={URL_SETTINGS_ATTRIBUTE_SET_DETAILS}
              element={<AttributeSetDetailsPage />}
            />
            <Route
              path={URL_SETTINGS_ATTRIBUTES}
              element={<AttributesPage />}
            />
            <Route path={URL_SETTINGS_BALRATE} element={<BalRatePage />} />
            <Route
              path={URL_SETTINGS_ORDER_PAGE_TEMPLATE}
              element={<OrderPageTemplatesPage />}
            />
            <Route
              path={URL_SETTINGS_ORDER_PAGE_TEMPLATE_DETAILS}
              element={<OrderPageTemplateDetailsPage />}
            />
            <Route
              path={URL_SETTINGS_UNITOFMEASURE}
              element={<UnitOfMeasurePage />}
            />

            <Route path={'/'} element={<HomePage />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
        {Toaster.getContainer()}
      </Provider>
    </AppProvider>
  );
};

export default App;
