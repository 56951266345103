import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import Spinner from '../frameWork/Spinner';
import AttachmentService from '../../services/attachment/AttachmentService';
import Toaster from '../common/Toaster';
import iAttachment from '../../types/asset/iAttachment';
import Window from '../frameWork/Window';
import AssetService from '../../services/asset/AssetService';

const Wrapper = styled.div`
  display: flex;
  gap: 4px;
`;
type iAttachmentDiv = {
  id: string;
  testId?: string;
  className?: string;
  elementAfter?: ReactNode;
};
const AttachmentDiv = ({
  id,
  testId,
  className,
  elementAfter,
}: iAttachmentDiv) => {
  const componentName = 'AttachmentDiv';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [isLoading, setIsLoading] = useState(true);
  const [attachment, setAttachment] = useState<iAttachment | null>(null);

  useEffect(() => {
    const idStr = `${id || ''}`.trim();
    if (idStr === '') {
      setIsLoading(false);
      setAttachment(null);
      return;
    }
    let isCanceled = false;
    setIsLoading(true);
    AttachmentService.getAll({
      where: JSON.stringify({ id }),
      include: 'Asset',
      perPage: 1,
    })
      .then((resp) => {
        if (isCanceled) {
          return;
        }
        const result = resp.data || [];
        setAttachment(result.length > 0 ? result[0] : null);
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [id]);

  const getTempUrl = () => {
    if (!attachment || !attachment.Asset) {
      return;
    }

    const url = `${attachment.Asset?.url || ''}`.trim();
    if (attachment.Asset?.isPublic === true && url !== '') {
      Window.open(url);
      return;
    }
    setIsLoading(true);
    AssetService.getPublicUrl(attachment.assetId)
      .then((resp) => {
        Window.open(resp.url);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (!attachment || !attachment.Asset) {
      return null;
    }
    return (
      <>
        <a
          onClick={getTempUrl}
          className={'cursor-pointer'}
          data-testid={'view-link'}
        >
          {attachment.Asset?.fileName || ''}
        </a>
        {elementAfter}
      </>
    );
  };

  return (
    <Wrapper
      className={`AttachmentDiv ${className || ''}`}
      data-testid={testIdStr}
    >
      {getContent()}
    </Wrapper>
  );
};

export default AttachmentDiv;
