import iBaseType from '../iBaseType';
import iBuildStylePrice from './iBuildStylePrice';
import iHouseStorey from './iHouseStorey';

export const sizeFields = [
  'houseSize',
  'alfrescoSize',
  'balconySize',
  'pergolaSize',
  'garageSize',
  'carportSize',
  'porchSize',
  'verandaSize',
];
type iBuildStyle = iBaseType & {
  name: string;
  description: string | null;
  noOfBeds: number | null;
  noOfBaths: number | null;
  noOfCars: number | null;
  floorArea: number | null;
  houseLength: number | null;
  houseWidth: number | null;
  minBlockWidth: number | null;
  minBlockDepth: number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings: any | null;
  isCustomised: boolean;
  LatestPrice?: iBuildStylePrice;
  storeyId: string | null;
  Storey?: iHouseStorey;

  houseSizeInSquare: number | null;
  houseSize: number | null;
  alfrescoSize: number | null;
  balconySize: number | null;
  pergolaSize: number | null;
  garageSize: number | null;
  carportSize: number | null;
  porchSize: number | null;
  verandaSize: number | null;
};

export default iBuildStyle;
