import { Document, Page, Text, StyleSheet } from '@react-pdf/renderer';
import iPDFData from './iPDFData';

const convertDataToPDFComponent = (data: iPDFData) => {
  const styles = StyleSheet.create({
    page: { margin: 30 },
  });

  return (
    <Document>
      {(data.pages || []).map((page, index) => {
        return (
          <Page
            key={index}
            size={page.size || 'A4'}
            orientation={'portrait'}
            style={styles.page}
          >
            {(page.content || []).map((pageContent, ind) => {
              return <Text key={ind}>{pageContent.content || ''}</Text>;
            })}
          </Page>
        );
      })}
    </Document>
  );
};

const PDFEditorHelper = { convertDataToPDFComponent };

export default PDFEditorHelper;
