import iProduct from '../../../types/product/iProduct';
import styled from 'styled-components';
import EntityNames from '../../../helpers/EntityNames';
import { AssetTypes } from '../../../types/asset/iAsset';
import AttachmentPreviewPanel from '../../asset/AttachmentPreviewPanel';
import Tokens from '../../frameWork/Tokens';
import InlineEdit from '../../frameWork/InlineEdit';
import { useState } from 'react';
import { iConfigParams } from '../../../services/AppService';
import ProductService from '../../../services/product/ProductService';
import Toaster from '../../common/Toaster';
import InlineTextArea from '../../frameWork/InlineTextArea';
import UnitOfMeasureSelector from '../../unitOfMeasure/UnitOfMeasureSelector';
import ProductTypeSelector from '../../productType/ProductTypeSelector';
import { iOption } from '../../frameWork/Select';

const Wrapper = styled.div`
  display: flex;
  gap: ${Tokens('space.200', '1rem')};
  flex-wrap: wrap;
  .photos {
    flex-grow: 1;
  }

  .general-div {
    padding: ${Tokens('space.100', '0.5rem')};
    min-width: 10rem;
    width: 30%;
    max-width: 100%;
  }
  .selector-div {
    gap: ${Tokens('space.100', '0.5rem')};
    display: flex;
  }
  .selector {
    flex: 1;
  }
`;

export type iProductDetailsGeneralPanel = {
  product: iProduct;
  isDisabled?: boolean;
  onSaved?: (saved: iProduct) => void;
  className?: string;
  testId?: string;
};
const ProductDetailsGeneralPanel = ({
  className,
  product,
  onSaved,
  isDisabled,
  testId,
}: iProductDetailsGeneralPanel) => {
  const testIdStr = `${testId || ''}-product-details-general`;
  const [isSaving, setIsSaving] = useState(false);

  const handleUpdate = (data: iConfigParams) => {
    if (Object.keys(data).length <= 0) {
      return;
    }
    setIsSaving(true);
    ProductService.update(product.id, data)
      .then((resp) => {
        setIsSaving(false);
        onSaved && onSaved(resp);
      })
      .catch((error) => {
        setIsSaving(false);
        Toaster.showApiError(error);
      });
  };

  return (
    <Wrapper
      className={`product-details-general ${className || ''}`}
      data-testid={testIdStr}
    >
      <AttachmentPreviewPanel
        className={'photos'}
        entityName={EntityNames.Product}
        entityId={product.id}
        types={[AssetTypes.PRODUCT_PHOTOS]}
        setNewAssetToPublic={true}
        allowSettingExistingAssetPublic={true}
        allowCreate
        allowDelete
      />
      <div className={'general-div'}>
        <InlineEdit
          isRequired
          testId={'product-code'}
          readViewFitContainerWidth
          label={'Product Code'}
          placeHolder={'Internal product code'}
          isDisabled={isSaving || isDisabled}
          value={product.productCode || ''}
          validate={(newValue) => {
            return `${newValue || ''}`.trim() === ''
              ? 'Product Code is required.'
              : '';
          }}
          onConfirm={(newValue) =>
            handleUpdate({
              productCode: newValue,
            })
          }
        />
        <InlineEdit
          testId={'name'}
          isRequired
          readViewFitContainerWidth
          label={'Product Name'}
          placeHolder={'Product Name'}
          isDisabled={isSaving || isDisabled}
          value={product.name || ''}
          validate={(newValue) => {
            return `${newValue || ''}`.trim() === ''
              ? 'Product Name is required.'
              : '';
          }}
          onConfirm={(newValue) =>
            handleUpdate({
              name: newValue,
            })
          }
        />
        <InlineEdit
          testId={'short-desc'}
          readViewFitContainerWidth
          label={'Short Description'}
          placeHolder={'Short Description'}
          isDisabled={isSaving || isDisabled}
          value={product.shortDescription || ''}
          onConfirm={(newValue) =>
            handleUpdate({
              shortDescription: newValue,
            })
          }
        />
        <div className={'selector-div'}>
          <ProductTypeSelector
            className="selector"
            testId={'product-type'}
            readViewFitContainerWidth
            label={'Product Type'}
            value={product.typeId || undefined}
            isDisabled={isSaving || isDisabled}
            onChange={(selected: iOption) =>
              handleUpdate({ typeId: selected?.data?.id || null })
            }
          />

          <UnitOfMeasureSelector
            className="selector"
            testId={'unit-of-measure'}
            readViewFitContainerWidth
            label={'Unit Of Measure'}
            value={product.uomId}
            isDisabled={isSaving || isDisabled}
            onChange={(selected: iOption) =>
              handleUpdate({ typeId: selected?.data?.id || null })
            }
          />
        </div>
        {/* <ProductTypeSelector
          testId={'product-type'}
          readViewFitContainerWidth
          label={'Product Type'}
          value={product.typeId || undefined}
          isDisabled={isSaving || isDisabled}
          onChange={(selected: iOption) =>
            handleUpdate({ typeId: selected?.data?.id || null })
          }
        />

        <UnitOfMeasureSelector
          testId={'unit-of-measure'}
          readViewFitContainerWidth
          label={'Unit Of Measure'}
          value={product.uomId}
          isDisabled={isSaving || isDisabled}
          onChange={(selected: iOption) =>
            handleUpdate({ typeId: selected?.data?.id || null })
          }
        /> */}
      </div>
      <div className={'full-width'}>
        <InlineTextArea
          testId={'full-desc'}
          readViewFitContainerWidth
          label={'Full Description'}
          placeHolder={'Full Description'}
          isDisabled={isSaving || isDisabled}
          value={product.fullDescription || ''}
          onConfirm={(newValue) =>
            handleUpdate({
              fullDescription: newValue,
            })
          }
        />
      </div>
    </Wrapper>
  );
};

export default ProductDetailsGeneralPanel;
