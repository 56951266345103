import iAttributeSet, {
  AttributeSetCodes,
} from '../../types/attribute/iAttributeSet';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import Flex from '../frameWork/Flex';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import { iAttributeItemWithValueMap } from '../../types/attribute/iAttributeItem';
import iAttribute, {
  AttributeForSections,
} from '../../types/attribute/iAttribute';
import AttributeItemService from '../../services/attribute/AttributeItemService';
import { iCellParams } from '../../helpers/DynamicTableHelper';
import AttributeValueService from '../../services/attribute/AttributeValueService';
import AttributeItemEditPopupBtn from './components/AttributeItemEditPopupBtn';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import Icons from '../frameWork/Icons';
import MathHelper from '../../helpers/MathHelper';
import { iRenderPopupBtnFn } from '../common/PopupBtn';
import AttributeSettingsHelper from './components/AttributeSettingsHelper';
import EntityNames from '../../helpers/EntityNames';
import AttributeInputHelper from './components/AttributeInputHelper';
import Heading from '../frameWork/Heading';
import RefreshIconBtn from '../pagination/RefreshIconBtn';
import Tokens from '../frameWork/Tokens';

const Wrapper = styled.div`
  width: 100%;
  td.dynamic-tbl-cell-area {
    width: 100px;
  }
  td.dynamic-tbl-cell-qty {
    width: 60px;
  }
  td.dynamic-tbl-cell-btns {
    text-align: right;
    .btns-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  td {
    .product-div.picture {
      .pic-thumb {
        img {
          width: 54px !important;
          height: 54px !important;
          object-fit: contain;
          object-position: center center;
          -o-object-fit: contain;
          -o-object-position: center center;
        }
      }
      .name-wrapper {
        > * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &.is-invalid {
      background-color: ${Tokens('color.background.accent.red.subtler')};
      &:hover {
        background-color: ${Tokens(
          'color.background.accent.red.subtler.hovered',
        )};
      }
    }
  }
`;

type iAttributeValueEditTable = {
  attributeSetCode: AttributeSetCodes;
  className?: string;
  testId?: string;
  entityId: string;
  entityName: EntityNames;
  allowCreate?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
  title?: ReactNode;
  needQty?: boolean;
  needArea?: boolean;
  attributeSection: AttributeForSections;
  providedAttrSet?: iAttributeSet;
  onRefreshBtnClicked?: () => void;
};
const AttributeItemEditTable = ({
  attributeSetCode,
  className = '',
  testId = '',
  attributeSection,
  entityId,
  entityName,
  allowCreate = true,
  allowEdit = true,
  allowDelete = true,
  needQty = false,
  needArea = false,
  providedAttrSet,
  onRefreshBtnClicked,
  title,
}: iAttributeValueEditTable) => {
  const testIdStr = `${testId}-attribute-value-edit-table`;
  const [attributes, setAttributes] = useState<iAttribute[]>([]);
  const [viewingAttrSet, setViewingAttrSet] = useState<iAttributeSet | null>(
    null,
  );
  const { state, renderDataTable, renderDeleteBtn, onRefresh } =
    useListCrudHook<iAttributeItemWithValueMap>({
      getFn: async () => {
        const { attributes, itemsWithValueMap, attributeSet } =
          await AttributeInputHelper.getDataForInputPanel({
            entityId,
            entityName,
            attributeSetCode,
            providedAttrSet,
          });
        setAttributes(attributes);
        setViewingAttrSet(attributeSet);
        return itemsWithValueMap;
      },
    });

  const getEditBtn = (
    data?: iAttributeItemWithValueMap,
    renderBtn?: iRenderPopupBtnFn,
    btnClassName?: string,
  ) => {
    const name = attributeSetCode.replace('_', ' ');
    const dataId = `${data?.id || ''}`.trim();
    const label =
      dataId === '' ? `Create a new ${name}` : `Updating this ${name}`;
    if (state.isLoading === true) {
      return null;
    }
    return (
      <AttributeItemEditPopupBtn
        attributeItem={data}
        attributeSetCode={attributeSetCode}
        entityId={entityId}
        entityName={entityName}
        onSaved={() => onRefresh()}
        attributeSection={attributeSection}
        renderBtn={(onClick) => {
          if (renderBtn) {
            return renderBtn(onClick);
          }
          return getCreateIconBtn({
            className: btnClassName,
            onClick: onClick,
            testId: testId || `create-edit-btn-${attributeSetCode}`,
            appearance: 'subtle',
            label,
            isTooltipDisabled: false,
            icon: () =>
              dataId === '' ? (
                <Icons.AddIcon size="small" label={''} />
              ) : (
                <Icons.EditFilledIcon size="small" label={''} />
              ),
          });
        }}
      />
    );
  };

  const getColumns = () => {
    return [
      ...(needArea === true
        ? [
            {
              key: 'area',
              header: 'Area',
              cell: ({ data }: iCellParams<iAttributeItemWithValueMap>) => {
                return `${data.HouseArea?.name || ''}`;
              },
            },
          ]
        : []),
      ...(needQty === true
        ? [
            {
              key: 'qty',
              header: 'Qty',
              cell: ({ data }: iCellParams<iAttributeItemWithValueMap>) => {
                return `${data.qty || ''}`;
              },
            },
          ]
        : []),
      ...AttributeSettingsHelper.filterReadableAttrsForSection(
        attributes,
        attributeSection,
      ).map((attribute) => {
        const isRequired =
          AttributeSettingsHelper.isAttributeRequiredForSection(
            attribute,
            attributeSection,
          );
        return {
          key: attribute.id,
          getCellClassName: ({
            data,
          }: iCellParams<iAttributeItemWithValueMap>) => {
            const valueMap = data.valuesMap || {};
            const errors = AttributeInputHelper.getAttributeValueErrors({
              attribute,
              attributeSection,
              attributeValue: valueMap[attribute.id],
            });
            return attribute.id in errors ? 'is-invalid' : '';
          },
          header: (
            <>
              {attribute.name}
              {isRequired !== true ? null : (
                <span className={'text-danger'}> * </span>
              )}
            </>
          ),
          cell: ({ data }: iCellParams<iAttributeItemWithValueMap>) => {
            const valueMap = data.valuesMap || {};
            return (
              <div className={`display-val-div`}>
                {attribute.id in valueMap
                  ? AttributeInputHelper.displayValue(
                      attribute,
                      valueMap[attribute.id],
                    )
                  : ' '}
              </div>
            );
          },
        };
      }),
      ...(allowDelete !== true && allowEdit !== true
        ? []
        : [
            {
              key: 'btns',
              header: '',
              isDefault: true,
              cell: ({ data }: iCellParams<iAttributeItemWithValueMap>) => {
                return (
                  <div className={'btns-wrapper'}>
                    {allowEdit && getEditBtn(data)}
                    {allowDelete &&
                      renderDeleteBtn({
                        deletingModel: data,
                        deleteFnc: async () => {
                          const values = Object.values(data.valuesMap || {});
                          return Promise.all([
                            AttributeItemService.deactivate(data.id),
                            ...values.map((value) =>
                              AttributeValueService.deactivate(value.id),
                            ),
                          ]);
                        },
                        getDisplayName: () =>
                          attributeSetCode.replace('_', ' '),
                      })}
                  </div>
                );
              },
            },
          ]),
    ];
  };

  const getTotalQtyDiv = () => {
    if (needQty !== true) {
      return null;
    }
    const totalQty = (state.data.data || []).reduce((sum, attributeItem) => {
      return MathHelper.add(sum, attributeItem.qty || 0);
    }, 0);
    return <small className={'total-qty'}>(Total: {totalQty})</small>;
  };

  const getTitleRow = () => {
    if (title) {
      return <div>{title}</div>;
    }
    return <Heading size={'small'}>{viewingAttrSet?.name}</Heading>;
  };

  if (!viewingAttrSet) {
    return null;
  }

  return (
    <Wrapper
      className={`AttributeValueEditTable ${className}`}
      data-testid={testIdStr}
    >
      <PageTitleWithCreateBtn
        createBtn={
          allowCreate ? getEditBtn(undefined, undefined, 'size-sm') : null
        }
        title={
          <Flex className={'align-items-end gap-05'}>
            {getTitleRow()}
            {getTotalQtyDiv()}
            <RefreshIconBtn
              onClick={() => {
                if (onRefreshBtnClicked) {
                  onRefreshBtnClicked();
                  return;
                }
                onRefresh();
              }}
            />
          </Flex>
        }
      />
      {renderDataTable({
        columns: getColumns(),
        onRefreshBtnClicked,
        tblProps: {
          topRow: <></>,
          testId: `${testIdStr}-table`,
        },
      })}
    </Wrapper>
  );
};

export default AttributeItemEditTable;
