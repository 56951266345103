import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import iBalRate from '../../types/system/iBalRate';
import BalRateService from '../../services/system/BalRateService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import StringHelper from '../../helpers/StringHelper';

type iBalRateList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};

const BalRateList = ({ allowDelete, headerProps }: iBalRateList) => {
  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iBalRate>({
      sort: `name:ASC`,
      getFn: (params) =>
        BalRateService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getEditBtn = (BalRate?: iBalRate) => {
    return renderEntityEditPopBtn<iBalRate>({
      editingEntity: BalRate,
      entityName: 'BalRate',
      createFn: (data) => BalRateService.create(data),
      updateFn: (id, data) => BalRateService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'BalRate-name',
        },
        {
          fieldName: 'rate',
          label: 'Rate',
          isDisabled,
          isRequired: true,
          value: entity?.rate || '',
          testId: 'BalRate-rate',
          isValid: (value: string) => {
            const valStr = `${value || ''}`.trim();
            if (!StringHelper.isNumeric(valStr)) {
              return {
                isValid: false,
                errMessages: ['Rate needs to be a number'],
              };
            }
            return {
              isValid: true,
              errMessages: [],
            };
          },
        },
        {
          fieldName: 'comments',
          label: 'Comments',
          isDisabled,
          value: entity?.comments || '',
          testId: 'BalRate-comments',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iBalRate>[] => [
    {
      key: 'name',
      header: 'Name',
      cell: ({ data }: iCellParams<iBalRate>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'rate',
      header: 'Rate',
      cell: ({ data }: iCellParams<iBalRate>) => `${data?.rate || ''}`,
    },
    {
      key: 'comments',
      header: 'Comments',
      cell: ({ data }: iCellParams<iBalRate>) => `${data?.comments || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iBalRate>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iBalRate>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => BalRateService.deactivate(data.id),
                    getDisplayName: (BalRate) => BalRate.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: 'BalRate-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default BalRateList;
