import {
  iHeadCellType,
  iHeadType,
  iRowCellType,
  iRowType,
} from '../components/frameWork/DynamicTable';
import * as _ from 'lodash';
import React from 'react';
import ModifyBy from '../components/common/ModifyBy';
import iUser from '../types/system/iUser';
import LocalStorageService from '../services/LocalStorageService';
import DynamicTableColSelectBtn, {
  iDynamicTableColSelectBtn,
} from '../components/frameWork/DynamicTableColSelectBtn';
import PaginationDetails, {
  iPaginationDetail,
} from '../components/pagination/PaginationDetails';
import Flex from '../components/frameWork/Flex';
import Icons from '../components/frameWork/Icons';
import moment from 'moment';

export type iDynamicBaseType = { id: string };

export type iHeaderParams<T extends iDynamicBaseType> = {
  col: iTableColumn<T>;
};

export type iCellParams<T extends iDynamicBaseType> = {
  col: iTableColumn<T>;
  data: T;
};

export type iTableColumn<T extends iDynamicBaseType> = Omit<
  iHeadCellType,
  'content'
> & {
  key: string;
  isDefault?: boolean;
  isSelectable?: boolean;
  group?: string;
  header: React.ReactNode | ((params: iHeaderParams<T>) => React.ReactNode);
  cell: string | ((params: iCellParams<T>) => React.ReactNode);
  getCellClassName?: string | ((params: iCellParams<T>) => React.ReactNode);
};

const getHead = (data: iHeadCellType[], extra = {}): iHeadType => {
  return {
    cells: data,
    ...extra,
  };
};

const getRows = <T extends iDynamicBaseType>(
  data: T[],
  eachRow: (row: T) => iRowCellType[],
  extra = {},
): iRowType[] => {
  return data.map((row: T) => ({
    key: row.id,
    cells: eachRow(row),
    ...extra,
  }));
};

const handleNullException = (
  value: string | null | undefined,
  showAsNumber = false,
) => {
  if (_.isNull(value) || _.isUndefined(value)) {
    return showAsNumber ? '0' : '';
  }
  return value;
};

const getCreatedAndUpdatedColumns = <
  T extends {
    id: string;
    CreatedBy?: iUser;
    createdAt: string;
    UpdatedBy?: iUser;
    updatedAt: string;
  },
>(
  isCreatedDefault = false,
  isUpdatedDefault = false,
): iTableColumn<T>[] => {
  return [
    {
      key: 'created',
      header: 'Created',
      isDefault: isCreatedDefault,
      isSelectable: true,
      cell: ({ data }) => (
        <ModifyBy by={data?.CreatedBy} at={data?.createdAt} />
      ),
    },
    {
      key: 'updated',
      header: 'Updated',
      isDefault: isUpdatedDefault,
      isSelectable: true,
      cell: ({ data }) => (
        <ModifyBy by={data?.UpdatedBy} at={data?.updatedAt} />
      ),
    },
  ];
};

export type iRenderColSelectiveBtn<T extends iDynamicBaseType> = Omit<
  iDynamicTableColSelectBtn<T>,
  'cols' | 'selectiveColumnKey'
>;
export type iRenderColSelectiveBtnFn<T extends iDynamicBaseType> = (
  props: iRenderColSelectiveBtn<T>,
) => React.ReactNode;
const getHeadAndRowsFromColumns = <T extends iDynamicBaseType>(
  cols: iTableColumn<T>[],
  data: T[],
  selectiveColumnKey?: string,
): {
  head: iHeadType;
  rows: iRowType[];
  renderColSelectiveBtn: iRenderColSelectiveBtnFn<T>;
} => {
  const sColKey = `${selectiveColumnKey || ''}`.trim();
  const selectedColumnKeys =
    sColKey === ''
      ? cols.map((col) => col.key)
      : LocalStorageService.getItem(sColKey) ||
        cols.filter((col) => col.isDefault === true).map((col) => col.key);

  const selectedCols = cols.filter(
    (col) => selectedColumnKeys.indexOf(col.key) >= 0,
  );

  const head = getHead(
    selectedCols.map((col) => {
      return {
        key: col.key,
        isSortable: col.isSortable,
        className: `dynamic-tbl-cell-${col.key} dynamic-tbl-head-cell`,
        content:
          typeof col.header === 'function' ? col.header({ col }) : col.header,
      };
    }),
  );
  const rows = getRows<T>(data, (row) => {
    return selectedCols.map((col) => {
      const colClass = !col.getCellClassName
        ? ''
        : typeof col.getCellClassName === 'function'
          ? col.getCellClassName({ col, data: row })
          : '';
      return {
        key: col.key,
        className:
          `dynamic-tbl-cell-${col.key} dynamic-tbl-row-cell ${colClass}`.trim(),
        content:
          typeof col.cell === 'function'
            ? col.cell({ col, data: row })
            : `${col.cell || ''}`,
      };
    });
  });
  return {
    head,
    rows,
    renderColSelectiveBtn: (props: iRenderColSelectiveBtn<T>) => {
      const localKey = `${selectiveColumnKey || ''}`.trim();
      if (localKey === '') {
        return null;
      }

      return (
        <DynamicTableColSelectBtn<T>
          {...props}
          cols={cols}
          selectiveColumnKey={localKey}
        />
      );
    },
  };
};

const getPaginationDetails = (paginationDetails?: iPaginationDetail) => {
  if (!paginationDetails) {
    return null;
  }
  return (
    <PaginationDetails
      className={'data-pagination'}
      testId={`dynamic-tbl-p-details`}
      {...paginationDetails}
    />
  );
};

const getTopRow = (left?: React.ReactNode, right?: React.ReactNode) => {
  if (!left && !right) {
    return null;
  }
  return (
    <Flex className={'justify-content-between align-items-center'}>
      <div>{left}</div>
      <div>{right}</div>
    </Flex>
  );
};

const getCheckedIcon = (value?: boolean | null, label = 'Yes') => {
  if (value !== true) {
    return null;
  }
  return (
    <span title={label} className={'text-success'}>
      <Icons.CheckIcon label={label} />
    </span>
  );
};

const displayDateCell = (date: Date | string | null, format = 'll') => {
  const dateString = `${date || ''}`.trim();
  if (dateString === '') {
    return '';
  }
  return moment(date).format(format);
};

const DynamicTableHelper = {
  getCheckedIcon,
  getTopRow,
  getPaginationDetails,
  getHead,
  getRows,
  handleNullException,
  getHeadAndRowsFromColumns,
  getCreatedAndUpdatedColumns,
  displayDateCell,
};

export default DynamicTableHelper;
