import SettingsPage from '../../layouts/SettingsPage';
import UnitOfMeasureList from '../../components/unitOfMeasure/UnitOfMeasureList';

const UnitOfMeasurePage = () => {
  return (
    <SettingsPage title={'Unit Of Measure'}>
      {(headerProps) => (
        <UnitOfMeasureList allowDelete headerProps={headerProps} />
      )}
    </SettingsPage>
  );
};

export default UnitOfMeasurePage;
