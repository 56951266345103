import SettingsPage from '../../layouts/SettingsPage';
import OrderPageTemplateList from '../../components/orderPageTemplate/OrderPageTemplateList';

const OrderPageTemplatesPage = () => {
  return (
    <SettingsPage title={'Order Page Templates'}>
      {(headerProps) => (
        <OrderPageTemplateList
          allowDelete
          allowCreate
          headerProps={headerProps}
        />
      )}
    </SettingsPage>
  );
};

export default OrderPageTemplatesPage;
