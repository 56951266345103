import ServiceHelper from '../helper/ServiceHelper';
import iOrderPageTemplate from '../../types/orderPageTemplate/iOrderPageTemplate';

const endPoint = '/orderPageTemplate';
const OrderPageTemplateService = {
  getAll: ServiceHelper.getPaginatedAll<iOrderPageTemplate>(endPoint),
  get: ServiceHelper.getById<iOrderPageTemplate>(endPoint),
  create: ServiceHelper.create<iOrderPageTemplate>(endPoint),
  update: ServiceHelper.updateById<iOrderPageTemplate>(endPoint),
  deactivate: ServiceHelper.deactivateById<iOrderPageTemplate>(endPoint),
};

export default OrderPageTemplateService;
