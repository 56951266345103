import React, { useEffect, useReducer, useState } from 'react';
import {
  reducer,
  iDataState,
  ActionKind,
  Action,
  getInitDataState,
  iViewingState,
} from './reducer';
import { iConfigParams } from '../../../services/AppService';
import iPaginatedResult from '../../../types/iPaginatedResult';
import Toaster, { TOAST_TYPE_SUCCESS } from '../../common/Toaster';
import MathHelper from '../../../helpers/MathHelper';
import DynamicTable, { iDynamicTable } from '../../frameWork/DynamicTable';
import DynamicTableHelper, {
  iDynamicBaseType,
  iRenderColSelectiveBtn,
  iTableColumn,
} from '../../../helpers/DynamicTableHelper';
import Lozenge from '../../frameWork/Lozenge';
import { IconButton } from '../../frameWork/Button';
import Icons from '../../frameWork/Icons';
import DeleteConfirmPopupBtn from '../../common/DeleteConfirmPopupBtn';
import EntityEditPopupBtn from '../../form/EntityEditPopupBtn';
import { getCreateIconBtn } from '../../common/PageTitleWithCreateBtn';
import iBaseType from '../../../types/iBaseType';
import { iEntityFormField } from '../../form/EntityEditPanel';
import { iRenderPopupBtnFn } from '../../common/PopupBtn';

export type iGetFn = {
  filter?: iConfigParams;
  sort?: string;
  currentPage?: number;
  perPage?: number;
};

type iUseListCrudHook<T> = {
  getFn: (props?: iGetFn) => Promise<iPaginatedResult<T>>;
  perPage?: number;
  sort?: string;
  filter?: iConfigParams;
  appendDataWhenLoadMore?: boolean;
  forceReload?: number;
};

export type iRenderDataTable<T extends iDynamicBaseType> = {
  columns: iTableColumn<T>[];
  showPagination?: boolean;
  showPageSizer?: boolean;
  tblLeftBottom?: React.ReactNode;
  tblProps?: iDynamicTable;
  selectiveColumnKey?: string;
  selectiveBtnProps?: Omit<iRenderColSelectiveBtn<T>, 'onChanged'>;
  onRefreshBtnClicked?: () => void;
};

const useListCrudHook = <T extends { id: string }>({
  getFn,
  perPage = 10,
  sort,
  filter,
  appendDataWhenLoadMore = false,
  forceReload = 0,
}: iUseListCrudHook<T>) => {
  const [state, dispatch] = useReducer<React.Reducer<iDataState<T>, Action<T>>>(
    reducer,
    getInitDataState(1, perPage),
  );
  const [viewingState, setViewingState] = useState<iViewingState>({
    perPage,
    currentPage: 1,
    sort,
    filter,
    version: 1,
  });

  useEffect(() => {
    let isCanceled = false;
    dispatch({ type: ActionKind.Loading, payload: {} });

    getFn({
      filter: viewingState.filter,
      sort: viewingState.sort,
      currentPage: viewingState.currentPage,
      perPage: viewingState.perPage,
    })
      .then((res) => {
        if (isCanceled) {
          return;
        }
        const newResult =
          appendDataWhenLoadMore === false || viewingState.currentPage === 1
            ? res
            : {
                ...res,
                data: [...(state.data.data || []), ...res.data],
              };
        dispatch({ type: ActionKind.Loaded, payload: { data: newResult } });
      })
      .catch((err) => {
        Toaster.showApiError(err);
        dispatch({ type: ActionKind.Loaded, payload: {} });
      });

    return () => {
      isCanceled = true;
    };
  }, [
    viewingState.sort,
    viewingState.filter,
    viewingState.currentPage,
    viewingState.perPage,
    viewingState.version,
    forceReload,
  ]);

  const onSetIsLoading = (isLoading = true) => {
    dispatch({
      type: isLoading ? ActionKind.Loading : ActionKind.Loaded,
      payload: {},
    });
  };

  const onSetSort = (sortStr: string) => {
    setViewingState((prevState) => ({
      ...prevState,
      sort: sortStr,
      currentPage: 1,
    }));
  };

  const onSetFilter = (filter: iConfigParams) => {
    setViewingState((prevState) => ({
      ...prevState,
      filter: filter,
      currentPage: 1,
    }));
  };

  const onRefresh = () => {
    setViewingState((prevState) => ({
      ...prevState,
      currentPage: 1,
      version: MathHelper.add(prevState.version, 1),
    }));
  };

  const onRefreshOnCurrentPage = () => {
    setViewingState((prevState) => ({
      ...prevState,
      version: MathHelper.add(prevState.version, 1),
    }));
  };

  const onRefreshWhenCreated = () => {
    setViewingState((prevState) => ({
      ...prevState,
      currentPage: 1,
      sort: 'createdAt:DESC',
      version: MathHelper.add(prevState.version, 1),
    }));
  };

  const onSetPage = (page: number) => {
    setViewingState((prevState) => ({
      ...prevState,
      currentPage: page,
      version: MathHelper.add(prevState.version, 1),
    }));
  };

  const onSetPageSize = (pageSize: number) => {
    setViewingState((prevState) => ({
      ...prevState,
      currentPage: 1,
      perPage: pageSize,
      version: MathHelper.add(prevState.version, 1),
    }));
  };

  const getSort = (index: number = 0) => {
    const sorts = `${viewingState.sort || ''}`.split(',').map((orderEle) => {
      const [sortKey, sortOrder = 'ASC'] = orderEle.split(':');
      return [sortKey, sortOrder];
    });
    return sorts.length > index ? sorts[index] : [];
  };

  const renderDataTable = ({
    columns,
    tblLeftBottom,
    showPagination = true,
    showPageSizer = false,
    tblProps,
    selectiveColumnKey,
    selectiveBtnProps,
    onRefreshBtnClicked,
  }: iRenderDataTable<T>) => {
    const [sortKey, sortOrder] = getSort();
    const {
      head: tblHead,
      rows: tblRows,
      renderColSelectiveBtn,
    } = DynamicTableHelper.getHeadAndRowsFromColumns<T>(
      columns,
      state.data.data || [],
      selectiveColumnKey,
    );

    const sortableKeys = columns
      .filter((col) => col.isSortable === true)
      .map((col) => col.key);

    return (
      <DynamicTable
        {...tblProps}
        topRow={
          tblProps?.topRow
            ? tblProps.topRow
            : DynamicTableHelper.getTopRow(
                DynamicTableHelper.getPaginationDetails({
                  hideWhenEmptyTotal: false,
                  from: state.data.from,
                  to: state.data.to,
                  total: state.data.total || 0,
                  onRefreshResults: () => {
                    if (onRefreshBtnClicked) {
                      onRefreshBtnClicked();
                      return;
                    }
                    onRefresh();
                  },
                }),
                renderColSelectiveBtn({
                  ...selectiveBtnProps,
                  onChanged: () => onRefresh(),
                }),
              )
        }
        isLoading={state.isLoading}
        onSetPage={(page) => {
          onSetPage(page);
        }}
        head={tblHead}
        rows={tblRows}
        rowsPerPage={viewingState.perPage}
        page={viewingState.currentPage}
        loadingSpinnerSize={'large'}
        totalPages={showPagination === true ? state.data.pages : undefined}
        bottomLeft={tblLeftBottom}
        onPageSizeChange={
          showPageSizer === true
            ? (pageSize) => onSetPageSize(pageSize)
            : undefined
        }
        {...(sortableKeys.indexOf(sortKey) >= 0
          ? {
              sortKey,
              sortOrder: sortOrder === 'ASC' ? 'ASC' : 'DESC',
              onSort: (data) => {
                onSetSort(`${data.key}:${data.sortOrder}`);
              },
            }
          : {})}
      />
    );
  };

  type iRenderDeleteBtn = {
    deletingModel: T;
    getDisplayName?: (data: T) => string;
    renderBtn?: iRenderPopupBtnFn;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deleteFnc: () => Promise<any>;
    onDeleted?: () => void;
    showDeletedMsg?: boolean;
  };
  const renderDeleteBtn = ({
    deletingModel,
    getDisplayName,
    deleteFnc,
    renderBtn,
    onDeleted,
    showDeletedMsg = true,
  }: iRenderDeleteBtn) => {
    return (
      <DeleteConfirmPopupBtn
        testId={`delete-btn-${deletingModel.id}`}
        titleId={`delete-btn-${deletingModel.id}`}
        message={
          getDisplayName ? (
            <>
              You are about to delete
              <Lozenge>{getDisplayName(deletingModel)}</Lozenge>.
            </>
          ) : undefined
        }
        deleteFnc={deleteFnc}
        renderBtn={(onClick) => {
          if (renderBtn) {
            return renderBtn(onClick);
          }
          return (
            <IconButton
              testId={`delete-icon-btn-${deletingModel.id}`}
              label={'delete'}
              appearance={'subtle'}
              icon={Icons.TrashIcon}
              onClick={onClick}
            />
          );
        }}
        onDeleted={() => {
          if (showDeletedMsg === true) {
            Toaster.showToast(
              <>
                {getDisplayName ? (
                  <>
                    <b>
                      <u>{getDisplayName(deletingModel)}</u>
                    </b>{' '}
                  </>
                ) : undefined}
                Deleted
              </>,
              TOAST_TYPE_SUCCESS,
            );
          }
          onRefresh();
          onDeleted && onDeleted();
        }}
      />
    );
  };

  type iRenderEditPopBtn<M extends iBaseType> = {
    className?: string;
    editingEntity?: M;
    testId?: string;
    isDisabled?: boolean;
    entityName: string;
    onSaved?: (saved: M, isCreated: boolean) => void;
    createFn?: (data: iConfigParams) => Promise<M>;
    updateFn: (id: string, data: iConfigParams) => Promise<M>;
    renderEditBtn: (data: {
      entity: M;
      className?: string;
      onClick: () => void;
    }) => React.ReactNode;
    getFormFields: (data: {
      entity?: M | null;
      isDisabled?: boolean;
    }) => iEntityFormField[];
  };
  const renderEntityEditPopBtn = <M extends iBaseType>({
    editingEntity,
    entityName,
    className,
    createFn,
    updateFn,
    getFormFields,
    renderEditBtn,
    testId,
    onSaved,
    isDisabled = false,
  }: iRenderEditPopBtn<M>) => {
    return (
      <EntityEditPopupBtn<M>
        isDisabled={isDisabled}
        entityName={entityName}
        entity={editingEntity}
        onSaved={(saved, isCreated) => {
          editingEntity ? onRefreshOnCurrentPage() : onRefreshWhenCreated();
          onSaved && onSaved(saved, isCreated);
        }}
        createFn={createFn}
        updateFn={updateFn}
        renderBtn={(onClick) =>
          editingEntity
            ? renderEditBtn({
                entity: editingEntity,
                onClick: onClick,
                className,
              })
            : getCreateIconBtn({
                className,
                onClick: onClick,
                testId: testId || `create-btn-${entityName}`,
                label: `Create a new ${entityName}`,
                isTooltipDisabled: false,
              })
        }
        getFormFields={getFormFields}
      />
    );
  };

  return {
    state,
    viewingState,
    getSort,
    onSetIsLoading,
    onSetSort,
    onSetFilter,
    onSetPage,
    onSetPageSize,
    onRefresh,
    renderDataTable,
    onRefreshWhenCreated,
    onRefreshOnCurrentPage,
    renderDeleteBtn,
    renderEntityEditPopBtn,
  };
};
export default useListCrudHook;
