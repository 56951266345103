import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import Flex from '../../frameWork/Flex';
import Icons from '../../frameWork/Icons';
import Tokens from '../../frameWork/Tokens';
import TextArea from '../../frameWork/TextArea';

const Wrapper = styled.div`
  position: relative;
  padding-top: 5rem;
  padding-bottom: 2rem;
  background-color: ${Tokens('color.background.accent.gray.subtlest')};
  overflow: hidden;
  .tool-bar {
    position: absolute;
    top: 0;
    padding: 0.5rem 0.5rem 0.25rem 1rem;
    left: 0px;
    right: 0px;
    background-color: #eee;
    z-index: 10;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;

    .btn {
      cursor: pointer;
      padding: 0.5rem;
      width: 4rem;
      &:hover {
        background-color: ${Tokens(
          'color.background.accent.gray.subtlest.hovered',
        )};
      }
    }
  }

  .content-wrapper {
    background-color: white;
    margin: 0px auto;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    height: calc(100% - 2rem);
    width: calc(100% - 4rem);
    padding: 1rem;
    max-width: 46rem;
  }
`;
type iPDFEditorEditView = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  testId?: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChanged: (changedData: any) => void;
  onShowPreview?: (showingPreview: boolean) => void;
  isShowingPreview?: boolean;
};
const PDFEditorEditView = ({
  testId,
  className,
  onChanged,
  onShowPreview,
  isShowingPreview,
}: iPDFEditorEditView) => {
  const componentName = 'PDFEditorEditView';
  const testIdStr = `${testId || ''}-${componentName}`;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const toolbar = toolbarRef.current;
      const wrapper = wrapperRef.current;

      if (toolbar && wrapper) {
        const toolbarOffsetTop =
          wrapper.getBoundingClientRect().top + toolbar.offsetTop;

        if (window.scrollY > toolbarOffsetTop) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Wrapper
      ref={wrapperRef}
      className={`${componentName} ${className || ''}`}
      data-testid={testIdStr}
    >
      <Flex
        className={`tool-bar align-items-stretch gap-05  ${isFixed ? 'fixed' : ''}`}
        ref={toolbarRef}
      >
        {[
          {
            name: 'container',
            label: 'Container',
            toolTip: 'new Container',
            icon: <Icons.LayoutOneColumnIcon label={'New Container'} />,
          },
          {
            name: 'text',
            label: 'Text',
            toolTip: 'new text',
            icon: <Icons.TextIcon label={'New Text'} />,
          },
          ...(onShowPreview
            ? [
                {
                  name: 'preview',
                  label: 'Preview',
                  toolTip: 'Show Preview',
                  icon: <Icons.TextIcon label={'Show Preview'} />,
                  onClick: () => onShowPreview(!isShowingPreview),
                },
              ]
            : []),
        ].map((item) => {
          return (
            <Flex
              key={item.name}
              title={item.toolTip}
              onClick={item.onClick}
              className={
                'btn align-items-center flex-column justify-content-end gap-025'
              }
            >
              {item.icon}
              <div>{item.label}</div>
            </Flex>
          );
        })}
      </Flex>
      <div className={`content-wrapper`}>
        <TextArea
          appearance={'subtle'}
          onChange={(event) => onChanged(event.target.value || '')}
        />
      </div>
    </Wrapper>
  );
};

export default PDFEditorEditView;
