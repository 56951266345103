import ProductTypeList from '../../components/productType/ProductTypeList';
import ProtectedPage from '../../layouts/ProtectedPage';

const ProductTypePage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `ProductType`,
      }}
    >
      {(headerProps) => (
        <ProductTypeList allowDelete headerProps={headerProps} />
      )}
    </ProtectedPage>
  );
};

export default ProductTypePage;
