import ServiceHelper from '../helper/ServiceHelper';
import iUnitOfMeasure from '../../types/unitOfMeasure/iUnitOfMeasure';

const endPoint = '/unitOfMeasurement';
const UnitOfMeasureService = {
  getAll: ServiceHelper.getPaginatedAll<iUnitOfMeasure>(endPoint),
  get: ServiceHelper.getById<iUnitOfMeasure>(endPoint),
  create: ServiceHelper.create<iUnitOfMeasure>(endPoint),
  update: ServiceHelper.updateById<iUnitOfMeasure>(endPoint),
  deactivate: ServiceHelper.deactivateById<iUnitOfMeasure>(endPoint),
};

export default UnitOfMeasureService;
