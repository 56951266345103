import styled from 'styled-components';
import Icons from '../frameWork/Icons';
import React from 'react';

const Wrapper = styled.div``;

type iRefreshIconBtn = {
  testId?: string;
  className?: string;
  onClick: () => void;
};

const RefreshIconBtn = ({ onClick, testId, className }: iRefreshIconBtn) => {
  const componentName = 'refresh-btn';
  const testIdStr = `${testId || ''}`.trim();
  return (
    <Wrapper
      className={`${componentName} cursor-pointer ${className || ''}`}
      onClick={onClick}
      data-testid={`refresh-data-btn-${testIdStr}-wrapper`}
    >
      <Icons.RefreshIcon
        label="refresh"
        size={'small'}
        testId={`refresh-data-btn-${testIdStr}-icon`}
      />
    </Wrapper>
  );
};

export default RefreshIconBtn;
