import iAttribute, {
  AttributeTypes,
} from '../../../types/attribute/iAttribute';
import styled from 'styled-components';
import TextArea from '../../frameWork/TextArea';
import { getErrorProps } from '../../form/FormError';
import { iUseAsForm } from '../../form/EntityEditPanel';
import Toggle from '../../frameWork/Toggle';
import { iConfigParams } from '../../../services/AppService';
import ProductTypeSelector from '../../productType/ProductTypeSelector';
import { iOption } from '../../frameWork/Select';

const Wrapper = styled.div``;
export type iAttributeSettingsEditPanel = {
  attribute?: iAttribute;
  isDisabled?: boolean;
  testId?: string;
  className?: string;
  useAsForm: iUseAsForm;
};
const AttributeSettingsEditPanel = ({
  testId,
  className,
  attribute,
  isDisabled,
  useAsForm,
}: iAttributeSettingsEditPanel) => {
  const componentName = 'attribute-settings-edit';
  const testIdStr = `${testId || ''}-${componentName}`;

  const getSelectedAttributeType = () => `${attribute?.type || ''}`.trim();

  const getSettingObject = () => {
    return attribute?.settings || {};
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFieldChanged = (newValue: iConfigParams) => {
    useAsForm.onFieldChange('settings', {
      ...getSettingObject(),
      ...newValue,
    });
  };

  const getOptionTextArea = () => {
    const settings = getSettingObject();
    const options = 'options' in settings ? settings.options : [];
    return (
      <TextArea
        label={`Options`}
        testId={'options'}
        isRequired
        isDisabled={isDisabled}
        value={options.join('\n')}
        helperMsg={`Each Option Per Line`}
        {...getErrorProps({
          fieldName: 'options',
          error: useAsForm.errorMap,
        })}
        onChange={(event) => {
          handleFieldChanged({ options: event.target.value.split('\n') });
        }}
      />
    );
  };

  const getProductDropdownSettings = () => {
    const settings = getSettingObject();
    return (
      <>
        <ProductTypeSelector
          isMulti
          label={`Product Types`}
          testId={'product-type-selector'}
          onChange={(options: iOption[] | null) => {
            handleFieldChanged({
              productTypeIds:
                options === null ? null : options.map((opt) => opt.value),
            });
          }}
          values={settings.productTypeIds}
          helperMsg={`IsForBuild products with selected types will be selectable. BLANK will allow all isForBuild Products`}
        />
        <Toggle
          label={'Show Product'}
          testId={'show-product'}
          helperMsg={'whether product will be shown, when selected.'}
          isDisabled={isDisabled}
          isChecked={settings.showSelected === true}
          onChange={(event) => {
            handleFieldChanged({
              showSelected: event.target.checked,
              ...(event.target.checked === false
                ? { showSelectedPic: false }
                : {}),
            });
          }}
        />
        {settings.showSelected === true && (
          <Toggle
            label={'Show Product Image'}
            testId={'show-product-img'}
            helperMsg={'whether product image will be shown, when selected.'}
            isDisabled={isDisabled}
            isChecked={settings.showSelectedPic === true}
            onChange={(event) =>
              handleFieldChanged({ showSelectedPic: event.target.checked })
            }
          />
        )}
      </>
    );
  };

  const getContent = () => {
    switch (getSelectedAttributeType()) {
      case AttributeTypes.DROPDOWN: {
        return getOptionTextArea();
      }
      case AttributeTypes.PRODUCT_DROPDOWN: {
        return getProductDropdownSettings();
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${componentName} ${className || ''}`}
    >
      {getContent()}
    </Wrapper>
  );
};

export default AttributeSettingsEditPanel;
