import SettingsPage from '../../layouts/SettingsPage';
import AttributeList from '../../components/attribute/AttributeList';

const AttributesPage = () => {
  return (
    <SettingsPage title={'Attributes'}>
      {(headerProps) => <AttributeList headerProps={headerProps} />}
    </SettingsPage>
  );
};

export default AttributesPage;
