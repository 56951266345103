import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import iBuildArea from '../../types/build/iBuildArea';
import BuildAreaService from '../../services/build/BuildAreaService';
import { iOption, iOptionWithData } from '../frameWork/Select';
import Flex from '../frameWork/Flex';
import Lozenge from '../frameWork/Lozenge';

export type iBuildAreaSelector = iPreloadAsyncSelectProps & {
  entityId: string;
  entityName: string;
  pageSize?: number;
};
const BuildAreaSelector = ({
  entityId,
  entityName,
  pageSize = 99999,
  ...props
}: iBuildAreaSelector) => {
  return (
    <PreloadedAsyncSelector<iBuildArea>
      placeholder={'Select an Area ...'}
      optionsUniqBy={(data: iBuildArea) => data.id}
      filterOption={(option: iOption, inputValue: string) => {
        const searchText = `${inputValue || ''}`.trim();
        if (searchText === '') {
          return true;
        }
        return `${option.data.data.HouseArea?.name || ''}`
          .trim()
          .toLowerCase()
          .includes(searchText.toLowerCase());
      }}
      formatOptionLabel={(data: iOptionWithData<iBuildArea>) => {
        const size = `${data.data.size || ''}`.trim();
        return (
          <Flex className={'align-items-center gap-1'}>
            <div>{data.data.HouseArea?.name}</div>
            {size === '' ? null : (
              <Lozenge appearance={'success'}>
                <i>
                  {size} m<sup>2</sup>
                </i>
              </Lozenge>
            )}
          </Flex>
        );
      }}
      getFn={async () => {
        return BuildAreaService.getAll({
          where: JSON.stringify({
            entityName,
            entityId,
            isActive: true,
          }),
          include: 'HouseArea',
          perPage: pageSize,
        });
      }}
      getValuesFn={async (values: string[]) => {
        if (values.length === 0) {
          return null;
        }
        return BuildAreaService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
          include: 'HouseArea',
        });
      }}
      {...props}
    />
  );
};

export default BuildAreaSelector;
