const reload = () => {
  return window.location.reload();
};

const open = (url: string, target?: string, features?: string) => {
  return window.open(url, target, features);
};

const Window = {
  reload,
  open,
};

export default Window;
