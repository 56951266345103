import ServiceHelper from '../helper/ServiceHelper';
import iBalRate from '../../types/system/iBalRate';

const endPoint = '/balRate';

const BalRateService = {
  getAll: ServiceHelper.getPaginatedAll<iBalRate>(endPoint),
  get: ServiceHelper.getById<iBalRate>(endPoint),
  create: ServiceHelper.create<iBalRate>(endPoint),
  update: ServiceHelper.updateById<iBalRate>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBalRate>(endPoint),
};

export default BalRateService;
