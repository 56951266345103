import React, { useCallback, useEffect } from 'react';
import Button, { ButtonProps } from '../frameWork/Button';
import PopupModal, { iPopupModal } from './PopupModal';

export type iModelProps = Omit<iPopupModal, 'titleId' | 'isOpen' | 'children'>;
export type iModelPropsFn = (
  setShowingModal: iSetShowingModalFn,
  isDisabled: boolean,
) => iModelProps;
export type iSetShowingModalFn = (showing: boolean) => void;
export type iRenderPopupBtnFn = (onClick: () => void) => React.ReactNode;
export type iPopupBtn = Omit<ButtonProps, 'children' | 'onClick'> & {
  renderBtn?: iRenderPopupBtnFn;
  btnTxt?: React.ReactNode;
  modalProps?: iModelPropsFn;
  isDisabled?: boolean;
  forceShown?: boolean;
  titleId: string;
  onClose?: (setModelShowing: iSetShowingModalFn) => void;
  onOpen?: () => void;
};

const PopupBtn = ({
  titleId,
  renderBtn,
  btnTxt = '',
  modalProps,
  isDisabled = false,
  forceShown = false,
  onClose,
  onOpen,
  ...props
}: iPopupBtn) => {
  const [showingPopup, setShowingPopup] = React.useState(false);
  const showOrHideModal = useCallback(
    (param: boolean) => setShowingPopup(param),
    [],
  );

  const triggerOnOpen = useCallback(() => {
    onOpen && onOpen();
  }, []);

  useEffect(() => {
    const showing = forceShown || forceShown;
    if (!showing) {
      return;
    }
    triggerOnOpen();
  }, [showingPopup, forceShown]);

  const getBtn = () => {
    if (!renderBtn) {
      return (
        <Button {...props} onClick={() => showOrHideModal(true)}>
          {btnTxt}
        </Button>
      );
    }
    return renderBtn(() => showOrHideModal(!showingPopup));
  };

  const handleClose = () => {
    if (isDisabled === true) {
      return;
    }

    showOrHideModal(false);
    onClose && onClose(showOrHideModal);
  };

  const getPopModal = () => {
    if (!showingPopup && !forceShown) {
      return null;
    }
    return (
      <PopupModal
        onClose={handleClose}
        titleId={titleId}
        {...(modalProps && modalProps(showOrHideModal, isDisabled))}
        isOpen={forceShown || showingPopup}
      />
    );
  };

  return (
    <>
      {getBtn()}
      {getPopModal()}
    </>
  );
};

export default PopupBtn;
