import { ReactNode, useEffect, useState } from 'react';
import Toaster from '../common/Toaster';
import styled from 'styled-components';
import StringHelper from '../../helpers/StringHelper';
import iHouseArea from '../../types/build/iHouseArea';
import HouseAreaService from '../../services/build/HouseAreaService';

const Wrapper = styled.div``;

export type iHouseAreaDiv = {
  id: string;
  testId?: string;
  className?: string;
  render?: (HouseArea: iHouseArea) => ReactNode;
};
const HouseAreaDiv = ({ testId, id, className, render }: iHouseAreaDiv) => {
  const componentName = 'HouseArea-div';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [houseArea, setHouseArea] = useState<iHouseArea | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (`${id || ''}`.trim() === '' || !StringHelper.isUUID(id)) {
      setHouseArea(null);
      return;
    }
    let isCanceled = false;
    setIsLoading(true);
    HouseAreaService.get(id)
      .then((resp) => {
        if (isCanceled) {
          return;
        }
        setHouseArea(resp);
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [id]);

  const getContent = () => {
    if (!StringHelper.isUUID(id)) {
      return id;
    }

    if (!houseArea || isLoading) {
      return null;
    }

    if (render) {
      return render(houseArea);
    }

    return <>{houseArea?.name || ''}</>;
  };

  return (
    <Wrapper
      className={`${componentName} ${className || ''}`}
      data-testid={testIdStr}
    >
      {getContent()}
    </Wrapper>
  );
};

export default HouseAreaDiv;
