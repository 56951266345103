import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import iAttributeSet, {
  AttributeSetCodes,
} from '../../types/attribute/iAttributeSet';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import { getErrorProps, iErrorMap } from '../form/FormError';
import TextField from '../frameWork/TextField';
import AttributeSetService from '../../services/attribute/AttributeSetService';
import Toaster from '../common/Toaster';
import Select from '../frameWork/Select';
import Toggle from '../frameWork/Toggle';

export type iAttributeSetEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  attributeSet?: iAttributeSet;
  isDisabled?: boolean;
  onSaved?: (saved: iAttributeSet) => void;
  excludingAttributeSetCodes?: string[];
};
const AttributeSetEditPopupBtn = ({
  attributeSet,
  isDisabled = false,
  excludingAttributeSetCodes = [],
  onSaved,
  onClose,
  ...props
}: iAttributeSetEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const attributeSetId = `${attributeSet?.id || ''}`.trim();
  const attributeSetCodes = Object.values(AttributeSetCodes);

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isSaving) {
      return;
    }
    setErrorMap({});
    setEditingData({});
    setModelShowing(false);
    onClose && onClose(setModelShowing);
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    const checkingData = {
      ...attributeSet,
      ...editingData,
    };
    if (`${checkingData.code || ''}`.trim() === '') {
      errors.code = ['Code is required'];
    }
    if (`${checkingData.name || ''}`.trim() === '') {
      errors.name = ['Name is required'];
    }
    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    if (!preSave()) {
      return;
    }
    const saveFn = () =>
      attributeSetId === ''
        ? AttributeSetService.create(editingData)
        : AttributeSetService.update(attributeSetId, editingData);
    setIsSaving(true);
    saveFn()
      .then((resp) => {
        setIsSaving(false);
        handleClose(setModelShowing);
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  const handleChanged = (fieldName: string, newValue: string | null) => {
    setEditingData({
      ...editingData,
      [fieldName]: newValue,
    });
  };

  const getSelectedValue = () => {
    const selectedCode =
      `${editingData.code || attributeSet?.code || ''}`.trim();
    if (selectedCode === '') {
      return undefined;
    }
    return {
      label: selectedCode,
      value: selectedCode,
    };
  };

  const getCurrentSettings = () => {
    return editingData?.settings || attributeSet?.settings || {};
  };

  const handleSettingsChanged = (newValue: iConfigParams) => {
    handleChanged('settings', {
      ...getCurrentSettings(),
      ...newValue,
    });
  };

  return (
    <PopupBtn
      {...props}
      titleId={'AttributeSetEditPopupBtn'}
      modalProps={(setModelShowing) => ({
        shouldScrollInViewport: true,
        title: (
          <>
            Editing Attribute Set <small>{attributeSet?.name}</small>
          </>
        ),
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            isDisabled:
              isDisabled || Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: 'Save',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setModelShowing),
          },
        }),
        body: (
          <>
            <Select
              label={'Code'}
              placeholder={'AttributeSet Code'}
              isDisabled={isDisabled || attributeSetId !== ''}
              isRequired
              value={getSelectedValue()}
              {...getErrorProps({
                error: errorMap,
                fieldName: 'code',
              })}
              options={attributeSetCodes
                .filter(
                  (attributeSetCode) =>
                    excludingAttributeSetCodes.indexOf(attributeSetCode) < 0,
                )
                .map((attributeSetCode) => ({
                  label: attributeSetCode,
                  value: attributeSetCode,
                }))}
              onChange={(selected) => {
                handleChanged('code', `${selected?.value || ''}`);
              }}
            />

            <TextField
              label={'Name'}
              placeholder={'Name'}
              isDisabled={isDisabled}
              isRequired
              value={editingData.name || attributeSet?.name || ''}
              {...getErrorProps({
                error: errorMap,
                fieldName: 'name',
              })}
              onChange={(event) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                handleChanged('name', event.target.value || '');
              }}
            />

            <TextField
              label={'Description'}
              placeholder={'Description'}
              isDisabled={isDisabled}
              value={editingData.description || attributeSet?.description || ''}
              {...getErrorProps({
                error: errorMap,
                fieldName: 'description',
              })}
              onChange={(event) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                handleChanged('description', event.target.value || '');
              }}
            />

            <Toggle
              label={'Has multiple?'}
              isChecked={getCurrentSettings().isMulti === true}
              helperMsg={'If yes, system display as a table to capture data.'}
              onChange={(event) => {
                handleSettingsChanged({
                  isMulti: event.target.checked === true,
                  needQty: false,
                  needArea: false,
                });
              }}
            />
          </>
        ),
      })}
    />
  );
};
export default AttributeSetEditPopupBtn;
