import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import TextField from '../frameWork/TextField';
import Toaster from '../common/Toaster';
import iBuildStylePrice from '../../types/build/iBuildStylePrice';
import BuildStylePriceService from '../../services/build/BuildStylePriceService';
import BuildStyleSelector from '../buildStyle/BuildStyleSelector';
import { iOption } from '../frameWork/Select';
import Flex from '../frameWork/Flex';
import { DatePicker } from '../frameWork/DateTimePicker';
import { getErrorProps, iErrorMap } from '../form/FormError';
import moment from 'moment';
import StringHelper from '../../helpers/StringHelper';

export type iBuildStyleEditPopupBtn = Omit<
  iPopupBtn,
  'titleId' | 'modalProps'
> & {
  isDisabled?: boolean;
  styleId?: string;
  stylePrice?: iBuildStylePrice;
  onSaved?: (saved: iBuildStylePrice, isCreated: boolean) => void;
};
const BuildStyleEditPopupBtn = ({
  stylePrice,
  styleId,
  isDisabled = false,
  onSaved,
  ...props
}: iBuildStyleEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const stylePriceId = `${stylePrice?.id || ''}`.trim();

  const handleClose = (setShowingModal: iSetShowingModalFn) => {
    setEditingData({});
    setErrorMap({});
    setIsSaving(false);
    setShowingModal(false);
  };

  const preCheck = () => {
    const errors: iErrorMap = {};

    const checkingData = {
      styleId,
      ...stylePrice,
      ...editingData,
    };
    const selectedStyleId = `${checkingData.styleId || ''}`.trim();
    if (selectedStyleId === '') {
      errors.styleId = ['Style is required.'];
    }
    const priceString = `${checkingData.price || ''}`.trim();
    if (priceString === '') {
      errors.price = ['Price is required.'];
    } else if (!StringHelper.isNumeric(priceString)) {
      errors.price = ['Price needs to be a number.'];
    }

    const startDateString = `${checkingData.startDate || ''}`.trim();
    if (startDateString === '') {
      errors.startDate = ['Start Date is required.'];
    }

    const endDateString = `${checkingData.endDate || ''}`.trim();
    if (
      endDateString !== '' &&
      moment(endDateString).isBefore(moment(startDateString))
    ) {
      errors.endDate = ['End Date needs to be same of later than Start Date.'];
    }

    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setShowingModal: iSetShowingModalFn) => {
    if (!preCheck()) {
      return;
    }
    setIsSaving(true);
    const submittingData = {
      ...editingData,
      ...(`${styleId || ''}`.trim() === '' ? {} : { styleId }),
    };

    const saveFnc = () =>
      stylePriceId === ''
        ? BuildStylePriceService.create(submittingData)
        : BuildStylePriceService.update(stylePriceId, submittingData);

    saveFnc()
      .then((resp) => {
        handleClose(setShowingModal);
        onSaved && onSaved(resp, stylePriceId === '');
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <PopupBtn
      titleId={'buildStylePrice-create-popup'}
      testId={'buildStylePrice-create-popup-btn'}
      shouldFitContainer
      {...props}
      modalProps={(setShowingModal) => ({
        shouldScrollInViewport: true,
        title:
          stylePriceId === '' ? `Creating a new price` : 'Updating a price',
        onClose: () => handleClose(setShowingModal),
        body: (
          <>
            <BuildStyleSelector
              label={'Style'}
              testId={'style-selector'}
              isRequired
              {...getErrorProps({ error: errorMap, fieldName: 'styleId' })}
              value={editingData.styleId || stylePrice?.styleId || styleId}
              isDisabled={
                `${styleId || ''}`.trim() !== '' || isSaving || isDisabled
              }
              onChange={(selected: iOption) => {
                setEditingData({
                  ...editingData,
                  styleId: `${selected.value || ''}`,
                });
              }}
            />

            <TextField
              isRequired
              testId={'price'}
              label={`Price`}
              placeholder={`The price. like 12304.3`}
              isDisabled={isSaving || isDisabled}
              value={editingData.price || stylePrice?.price || ''}
              {...getErrorProps({ error: errorMap, fieldName: 'price' })}
              elemBeforeInput={<div style={{ padding: '0 0 0 5px' }}>$</div>}
              onChange={(event) =>
                setEditingData({
                  ...editingData,
                  price:
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    `${event.target.value || ''}`,
                })
              }
            />

            <Flex className={'align-items-start gap-1'}>
              <DatePicker
                label={'Start Date'}
                testId={'startDate'}
                isRequired
                value={editingData.startDate || stylePrice?.startDate || ''}
                {...getErrorProps({ error: errorMap, fieldName: 'startDate' })}
                onChange={(selected) => {
                  setEditingData({
                    ...editingData,
                    startDate:
                      `${selected || ''}`.trim() !== ''
                        ? moment(selected).startOf('day').toISOString()
                        : '',
                  });
                }}
              />

              <DatePicker
                label={'End Date'}
                testId={'endDate'}
                value={editingData.endDate || stylePrice?.endDate || ''}
                {...getErrorProps({ error: errorMap, fieldName: 'endDate' })}
                onChange={(selected) => {
                  setEditingData({
                    ...editingData,
                    endDate:
                      `${selected || ''}`.trim() !== ''
                        ? moment(selected).endOf('day').toISOString()
                        : '',
                  });
                }}
              />
            </Flex>

            <TextField
              testId={'comments'}
              label={`Comments`}
              placeholder={`Some comments`}
              isDisabled={isSaving || isDisabled}
              value={editingData.comments || stylePrice?.comments || ''}
              onChange={(event) =>
                setEditingData({
                  ...editingData,
                  comments:
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    `${event.target.value || ''}`,
                })
              }
            />
          </>
        ),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setShowingModal),
          },
          actionBtnProps: {
            isDisabled: Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: stylePriceId === '' ? 'Create' : 'Update',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setShowingModal),
          },
        }),
      })}
    />
  );
};

export default BuildStyleEditPopupBtn;
