import ServiceHelper from '../helper/ServiceHelper';
import iProductType from '../../types/product/iProductType';

const endPoint = '/productType';
const ProductTypeService = {
  getAll: ServiceHelper.getPaginatedAll<iProductType>(endPoint),
  get: ServiceHelper.getById<iProductType>(endPoint),
  create: ServiceHelper.create<iProductType>(endPoint),
  update: ServiceHelper.updateById<iProductType>(endPoint),
  deactivate: ServiceHelper.deactivateById<iProductType>(endPoint),
};

export default ProductTypeService;
