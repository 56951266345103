import BalRateList from '../../components/balRate/BalRateList';
import SettingsPage from '../../layouts/SettingsPage';

const BalRatePage = () => {
  return (
    <SettingsPage title={'BalRate'}>
      {(headerProps) => <BalRateList allowDelete headerProps={headerProps} />}
    </SettingsPage>
  );
};

export default BalRatePage;
