import styled from 'styled-components';
import { useEffect, useState } from 'react';
import iAttribute from '../../types/attribute/iAttribute';
import AttributeService from '../../services/attribute/AttributeService';
import Toaster from '../common/Toaster';
import StringHelper from '../../helpers/StringHelper';

const Wrapper = styled.div``;

export type iAttributeDiv = {
  id: string;
  className?: string;
  testId?: string;
};
const AttributeDiv = ({ id, testId, className }: iAttributeDiv) => {
  const componentName = 'attribute-div';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [attribute, setAttribute] = useState<iAttribute | null>(null);

  useEffect(() => {
    const idStr = `${id || ''}`.trim();
    if (idStr === '' || !StringHelper.isUUID(idStr)) {
      return;
    }

    let isCanceled = false;
    setIsLoading(true);
    AttributeService.get(idStr, { include: 'AttributeSet' })
      .then((resp) => {
        if (isCanceled) {
          return;
        }
        setAttribute(resp);
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      isCanceled = true;
    };
  }, [id]);

  const getContent = () => {
    if (isLoading || !attribute) {
      return null;
    }
    return (
      <>
        {attribute.AttributeSet?.name} / {attribute.name}
      </>
    );
  };

  return (
    <Wrapper
      className={`${componentName} ${className || ''}`}
      data-testid={testIdStr}
    >
      {getContent()}
    </Wrapper>
  );
};

export default AttributeDiv;
