import EntityNames from '../../helpers/EntityNames';
import styled from 'styled-components';
import { AssetTypes } from '../../types/asset/iAsset';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import iAttachment from '../../types/asset/iAttachment';
import AttachmentService from '../../services/attachment/AttachmentService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { iDynamicTable } from '../frameWork/DynamicTable';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import React from 'react';
import AttachmentUploadPopupBtn from './AttachmentUploadPopupBtn';
import Icons from '../frameWork/Icons';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import UtilsService from '../../services/UtilsService';
import GetAssetPublicUrlPopupBtn from './GetAssetPublicUrlPopupBtn';

const Wrapper = styled.div`
  display: block;
  width: 100%;
`;

export type iAttachmentsTable = Omit<iComponentWithPageHeader, 'children'> & {
  entityName: EntityNames;
  entityId: string;
  className?: string;
  testId?: string;
  types?: AssetTypes[];
  tblProps?: iDynamicTable;
  allowCreate?: boolean;
  allowDelete?: boolean;
  selectiveColumnKey?: SelectiveColKeys;
  pageSize?: number;
};
const AttachmentsTable = ({
  entityId,
  entityName,
  className,
  testId,
  types = [],
  tblProps,
  allowCreate,
  allowDelete,
  headerProps,
  selectiveColumnKey,
  pageSize,
  ...props
}: iAttachmentsTable) => {
  const componentName = 'AttachmentsTable';
  const testIdStr = `${testId || ''}-${componentName}-${entityName}-${entityId}`;
  const { renderDataTable, onRefreshWhenCreated, renderDeleteBtn, onRefresh } =
    useListCrudHook<iAttachment>({
      perPage: pageSize,
      getFn: (params) =>
        AttachmentService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(types?.length > 0 ? { type: types } : {}),
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy,Asset',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getColumns = (): iTableColumn<iAttachment>[] => {
    return [
      {
        key: 'fileName',
        header: 'File',
        isDefault: true,
        isSelectable: false,
        cell: ({ data }: iCellParams<iAttachment>) => {
          if (!data.Asset) {
            return '';
          }
          return (
            <GetAssetPublicUrlPopupBtn
              asset={data.Asset}
              renderBtn={(onclick) => {
                return (
                  <a onClick={onclick} className={'cursor-pointer'}>
                    {data.Asset?.fileName || ''}
                  </a>
                );
              }}
            />
          );
        },
      },
      {
        key: 'fileSize',
        header: 'Size',
        isDefault: true,
        isSelectable: true,
        cell: ({ data }: iCellParams<iAttachment>) =>
          UtilsService.formatBytesToHuman(data.Asset?.fileSize || 0),
      },
      {
        key: 'type',
        header: 'Type',
        isDefault: false,
        isSelectable: true,
        cell: ({ data }: iCellParams<iAttachment>) => data.Asset?.type || '',
      },
      {
        key: 'mimeType',
        header: 'Format',
        isDefault: false,
        isSelectable: true,
        cell: ({ data }: iCellParams<iAttachment>) =>
          data.Asset?.mimeType || '',
      },
      {
        key: 'isPublic',
        header: 'Is Public',
        isDefault: false,
        isSelectable: true,
        cell: ({ data }: iCellParams<iAttachment>) =>
          DynamicTableHelper.getCheckedIcon(data.Asset?.isPublic === true),
      },
      ...DynamicTableHelper.getCreatedAndUpdatedColumns<iAttachment>(),
      ...(allowDelete !== true
        ? []
        : [
            {
              key: 'btns',
              header: '',
              isDefault: true,
              isSelectable: false,
              cell: ({ data }: iCellParams<iAttachment>) => {
                return (
                  <div className={'text-right'}>
                    {renderDeleteBtn({
                      deletingModel: data,
                      deleteFnc: () => AttachmentService.deactivate(data.id),
                      onDeleted: () => {
                        onRefresh();
                      },
                    })}
                  </div>
                );
              },
            },
          ]),
    ];
  };
  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${componentName} ${className || ''}`}
    >
      <ComponentWithPageHeader
        {...props}
        headerProps={
          headerProps && {
            ...headerProps,
            children: (
              <PageTitleWithCreateBtn
                createBtn={
                  allowCreate === true ? (
                    <AttachmentUploadPopupBtn
                      entityId={entityId}
                      entityName={entityName}
                      assetTypes={types}
                      isAssetPublic={false}
                      onUploaded={() => onRefreshWhenCreated()}
                      renderBtn={(onClick) => {
                        return getCreateIconBtn({
                          onClick,
                          className: 'size-sm',
                          icon: Icons.AddIcon,
                          isSelected: true,
                          spacing: 'compact',
                          label: 'new file',
                          testId: 'new-btn',
                        });
                      }}
                    />
                  ) : null
                }
                title={headerProps?.children}
              />
            ),
          }
        }
      >
        {renderDataTable({
          columns: getColumns(),
          tblProps,
          selectiveColumnKey,
        })}
      </ComponentWithPageHeader>
    </Wrapper>
  );
};

export default AttachmentsTable;
